import { useEffect, useRef, useState } from 'react';

const initialState = { width: 0, height: 0 };

const useParentSize = (ref, initialValues) => {
  const [dimensions, setDimensions] = useState(initialValues || initialState);
  const resizeObserverRef = useRef(null);

  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver((entries = []) => {
      if (entries[0]) {
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      }
    });

    if (ref.current?.parentElement) {
      resizeObserverRef.current.observe(ref.current.parentElement);
    }

    return () => {
      if (resizeObserverRef.current) resizeObserverRef.current.disconnect();
    };
  }, [ref]);
  return dimensions;
};

export default useParentSize;
