import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Facebook } from '@styled-icons/boxicons-logos/Facebook';
import { Twitter } from '@styled-icons/boxicons-logos/Twitter';
import { Linkedin } from '@styled-icons/boxicons-logos/Linkedin';
import { Location } from '@reach/router';
import encodeurl from 'encodeurl';
import { media } from 'styled-bootstrap-grid';

const Container = styled.div`
  width: 36px;
  background: #fff;
  box-shadow: ${({ theme }) => theme.shadows.card};
  border-radius: 16px;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 1;
  top: 50%;
  display: none;

  ${media.lg`
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(calc(-100% - 16px));
  `}
`;

const Item = styled.div`
  cursor: pointer;
  text-align: center;
  padding-bottom: 8px;
`;

const FB = styled(Item)`
  color: #4267b2;
`;

const TwitterItem = styled(Item)`
  color: #1da1f2;
`;

const LinkedinItem = styled(Item)`
  color: #2867b2;
`;

const ShareButtons = ({ className }) => {
  return (
    <Location>
      {({ location }) => {
        const handleClick = (shareURL) => {
          const siteUrl = encodeurl(location.href);
          const url = `${shareURL}${siteUrl}`;
          window.open(url, '_blank');
        };

        return (
          <Container className={className}>
            <FB
              onClick={() =>
                handleClick('https://www.facebook.com/sharer/sharer.php?u=')
              }
            >
              <Facebook size={24} />
            </FB>
            <TwitterItem
              onClick={() =>
                handleClick('https://twitter.com/intent/tweet?url=')
              }
            >
              <Twitter size={22} />
            </TwitterItem>
            <LinkedinItem
              onClick={() =>
                handleClick(
                  'https://www.linkedin.com/sharing/share-offsite/?url='
                )
              }
            >
              <Linkedin size={20} />
            </LinkedinItem>
          </Container>
        );
      }}
    </Location>
  );
};

export default ShareButtons;
