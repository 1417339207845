import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

const FactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  padding: 24px 8px;
  z-index: 1;
  text-align: start;
`;

const NumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`;

const StyledBar = styled.div`
  position: relative;
  height: ${({ shrinkNumber }) => (shrinkNumber ? '36px' : '58px')};
  margin-top: ${({ shrinkNumber }) => (shrinkNumber ? '22px' : '0px')};
  width: 5px;
  margin-right: 16px;
  background-color: ${({ theme }) => theme.palette.secondary.dark};
  ${media.md`
    height: ${({ shrinkNumber }) => (shrinkNumber ? '42px' : '76px')};
    margin-top: ${({ shrinkNumber }) => (shrinkNumber ? '32px' : '0px')};
    margin-right: 24px;
  `}
`;

const StyledNumber = styled.p`
  font-family: Montserrat;
  font-weight: 900;
  color: ${({ theme }) => theme.palette.primary.main};
  margin: 0px;
  padding: 0px;
  font-size: ${({ shrinkNumber }) => (shrinkNumber ? '48px' : '80px')};
  line-height: ${({ shrinkNumber }) => (shrinkNumber ? '36px' : '58px')};
  ${media.md`
    font-size: ${({ shrinkNumber }) => (shrinkNumber ? '56px' : '104px')};
    line-height: ${({ shrinkNumber }) => (shrinkNumber ? '42px' : '76px')};
  `}
`;

const StyledUnit = styled.p`
  font-family: Montserrat;
  font-weight: 900;
  color: ${({ theme }) => theme.palette.primary.main};
  margin: 0px;
  padding: 0px;
  font-size: 28px;
  line-height: 24px;
  margin-left: 8px;
  ${media.md`
    font-size: 36px;
    line-height: 28px;
    margin-left: 12px;
  `}
`;

const StyledClaim = styled.h4`
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 32px 0px 0px 0px;
`;

const StyledDescription = styled.div`
  p {
    font-family: Lato;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const FactCard = ({ fact_data: { number, unit, headline, description } }) => {
  const shrinkNumber = number.toString().length > 4 ? true : false;

  return (
    <FactContainer>
      <NumberContainer>
        <StyledBar shrinkNumber={shrinkNumber} />
        <StyledNumber shrinkNumber={shrinkNumber}>
          {number.toLocaleString()}
        </StyledNumber>
        {unit && <StyledUnit>{unit.text}</StyledUnit>}
      </NumberContainer>
      <StyledClaim>{headline.text}</StyledClaim>
      <StyledDescription>
        <div dangerouslySetInnerHTML={{ __html: description.html }} />
      </StyledDescription>
    </FactContainer>
  );
};

export default FactCard;
