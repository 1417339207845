import React from 'react';
import styled from 'styled-components';

const Dot = styled.div`
  position: relative;
  height: 6px;
  width: ${({ active }) => (active ? '64px' : '32px')};
  background: ${({ theme }) => theme.palette.grey[300]};
  margin: 0 5px;
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: width 0.125s ease-out;

  ${({ active, theme }) =>
    active &&
    `
    &:before {
      content: '';
      left: 0;
      position: absolute;
      height: 6px;
      width: 100%;
      background: ${theme.palette.primary.main};
      border-radius: 4px;
    }
  `}
`;

const SlideDot = ({ onClick, primary, ...rest }) => {
  const { active } = rest;

  return <Dot active={active} primary={primary} onClick={onClick} />;
};

export default SlideDot;
