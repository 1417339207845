import React, { Fragment } from 'react';
import styled from 'styled-components';
import Hyphenated from 'react-hyphen';
import de from 'hyphenated-de';

const SliceHeadlineSecondary = styled.h1`
  color: ${({ useSecondary, theme }) =>
    useSecondary ? theme.palette.text.secondary : theme.palette.text.primary};
  overflow-wrap: break-word;
  word-break: normal !important;
  hyphens: manual;
  font-size: ${({ size }) => (size === 'small' ? '30px' : '38px')};
`;

const HighlightNewline = styled.h1`
  padding: 0px;
  margin: 0px;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ size }) => (size === 'small' ? '30px' : '38px')};
`;

const Highlight = styled.span`
  padding: 0px;
  margin: 0px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const HighlightedHeadline = ({
  pre,
  highlight,
  post,
  highlightNewline,
  hasDarkBackground,
  size = 'large',
}) => {
  const HyphenatedText = ({ text }) => {
    let maximalWordLength = text
      .split(' ')
      .map((x) => x.length)
      .sort()[0];

    if (maximalWordLength > 17) {
      return (
        <Hyphenated>
          <Hyphenated language={de}>{text}</Hyphenated>
        </Hyphenated>
      );
    }
    return <Fragment>{text}</Fragment>;
  };

  return (
    <Fragment>
      {highlightNewline ? (
        <Fragment>
          {pre?.text && (
            <SliceHeadlineSecondary
              useSecondary={hasDarkBackground}
              size={size}
            >
              <HyphenatedText text={pre.text} />
              <span> </span>
            </SliceHeadlineSecondary>
          )}
          {highlight?.text && (
            <HighlightNewline size={size}>
              <HyphenatedText text={highlight.text} />
            </HighlightNewline>
          )}
          {post?.text && (
            <SliceHeadlineSecondary
              useSecondary={hasDarkBackground}
              size={size}
            >
              <HyphenatedText text={post.text} />
            </SliceHeadlineSecondary>
          )}
        </Fragment>
      ) : (
        <SliceHeadlineSecondary useSecondary={hasDarkBackground} size={size}>
          {pre?.text && <HyphenatedText text={pre.text} />}
          <span> </span>
          {highlight?.text && (
            <Highlight>
              <HyphenatedText text={highlight.text} />
            </Highlight>
          )}
          <span> </span>
          {post?.text && <HyphenatedText text={post.text} />}
        </SliceHeadlineSecondary>
      )}
    </Fragment>
  );
};

export default HighlightedHeadline;
