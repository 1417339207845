import React, { useRef, useState } from 'react';
import { useLocation } from '@reach/router';
import Modal from 'react-modal';
import styled from 'styled-components';
import { Col, Row, media } from 'styled-bootstrap-grid';
import { useForm } from 'react-hook-form';
import { graphql, useStaticQuery } from 'gatsby';
import LoadingButton from './LoadingButton';
import { Button } from './Buttons';
import RichText from './RichText';
import { X } from '@styled-icons/bootstrap/X';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  overflow: hidden;
  height: 100%;
`;

const Body = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 16px;
  ${media.lg`
    padding: 32px 104px 0px 104px;
  `}
`;

const Header = styled.div`
  background-color: ${({ theme }) => theme.palette.success.light};
  text-align: center;
  padding: 32px 16px 16px;

  ${media.lg`
    padding: 48px 104px;
  `}
`;

const Headline = styled.h1`
  margin-bottom: 8px;
  font-family: Lato;
  font-weight: 700;
  font-size: 38px;
  line-height: 46px;
  text-transform: none;
  word-wrap: normal !important;
`;

const Highlight = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const FormContainer = styled.div`
  padding: 12px;
`;

const FormControl = styled.div`
  position: relative;
  margin-bottom: 16px;

  ${({ type }) =>
    type === 'checkbox' &&
    `
    display: flex;
    align-items: center;

    input {
      margin-right: 8px;
    }
  `}
`;

const Label = styled.h4`
  margin-bottom: 16px;
`;

const Input = styled.input`
  padding: 8px 14px;
  background: #fff;
  outline: none !important;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.grey[400]};
  font-size: 14px;
  width: 100%;
  height: 45px;
  border-radius: 6px;

  &:focus {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }

  ${({ error, theme }) =>
    error &&
    `
    border-color: ${theme.palette.error.main} !important;
  `}
`;

const Notice = styled.p`
  p {
    font-size: 14px;
    margin-bottom: -24px;
  }
`;

const SignupButton = styled(LoadingButton)`
  width: 100%;
`;

const ErrorMessage = styled.p`
  position: absolute;
  color: ${({ theme }) => theme.palette.error.main};
  margin-bottom: 0;
  bottom: 0;
  transform: translateY(100%);
`;

const AfterSubmitContainer = styled.div`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  text-align: center;
`;

const Close = styled(X)`
  color: ${({ theme }) => theme.palette.text.primary};
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

const NewsletterDialog = ({ open, onClose, handleFormSubmit }) => {
  const { register, handleSubmit, errors, formState } = useForm();
  const [success, setSucces] = useState(false);
  const [error, setError] = useState(false);
  const location = useLocation();
  const pageLang = location.pathname.split('/').includes('de-de')
    ? 'de-de'
    : 'en-us';

  const {
    allPrismicNewsletterSignup: { edges },
  } = useStaticQuery(query);
  const data = edges.find((edge) => edge.node.lang == pageLang).node.data;
  const emailRef = useRef();
  const customStyles = {
    content: {
      zIndex: 1001,
      maxWidth: '700px',
      maxHeight: '600px',
      width: 'auto',
      height: 'auto',
      margin: 'auto',
      padding: '0px',
      background: '#ffffff',
      borderRadius: '8px',
      top: 16,
      left: 16,
      right: 16,
      bottom: 16,
    },
  };

  const onSubmit = async (values) => {
    try {
      await handleFormSubmit({
        values,
        privacyText: data.privacy_notice.text,
        marketingText: data.marketing_notice.text,
      });

      setSucces(true);
    } catch {
      setError(true);
    }
  };

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      ariaHideApp={false}
      style={customStyles}
      closeTimeoutMS={1000}
    >
      <Container>
        <Close size={40} onClick={onClose} />
        <Header>
          <Headline>
            {data.headline_pre_highlight.text}
            <span> </span>
            <Highlight>{data.headline_highlight.text}</Highlight>
          </Headline>
        </Header>
        <Body>
          <FormContainer>
            {success && (
              <AfterSubmitContainer>
                <h4>{data.success_title.text}</h4>
                <p>{data.success_message.text}</p>
                <Button onClick={onClose}>Close</Button>
              </AfterSubmitContainer>
            )}
            {error && (
              <AfterSubmitContainer>
                <p>{data.error_message.text}</p>
              </AfterSubmitContainer>
            )}
            {!success && !error && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col col={12}>
                    <FormControl>
                      <Label>{data.email_label.text}</Label>
                      <Input
                        ref={(ref) => {
                          emailRef.current = ref;
                          register({
                            required: 'Required',
                            pattern: {
                              value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                              message: 'Please enter a valid email',
                            },
                          })(ref);
                        }}
                        error={errors.email}
                        defaultValue={''}
                        type="email"
                        name="email"
                        disabled={formState.isSubmitting}
                        placeholder={data.placeholder.text}
                      />
                      {errors.email && (
                        <ErrorMessage>{errors.email.message}</ErrorMessage>
                      )}
                    </FormControl>
                  </Col>
                  <Col auto>
                    <FormControl type="checkbox">
                      <input
                        name="privacyAccepted"
                        ref={register({
                          required: 'Please agree to our privacy notice',
                        })}
                        type="checkbox"
                        disabled={formState.isSubmitting}
                      />
                      <Notice>
                        <RichText render={data.privacy_notice.raw} />
                      </Notice>
                      {errors.privacyAccepted && (
                        <ErrorMessage>
                          {errors.privacyAccepted.message}
                        </ErrorMessage>
                      )}
                    </FormControl>
                  </Col>
                  <Col col={12}>
                    <FormControl type="checkbox">
                      <input
                        name="marketingAccepted"
                        ref={register({
                          required: 'Please agree to our marketing notice',
                        })}
                        type="checkbox"
                        disabled={formState.isSubmitting}
                      />
                      <Notice>
                        <p>{data.marketing_notice.text}</p>
                      </Notice>
                      {errors.marketingAccepted && (
                        <ErrorMessage>
                          {errors.marketingAccepted.message}
                        </ErrorMessage>
                      )}
                    </FormControl>
                  </Col>
                  <Col col={12}>
                    <SignupButton
                      loading={formState.isSubmitting}
                      type="submit"
                      className="plausible-event-name=Newsletter+Signup+Submit"
                    >
                      {data.button_text.text}
                    </SignupButton>
                  </Col>
                </Row>
              </form>
            )}
          </FormContainer>
        </Body>
      </Container>
    </Modal>
  );
};

export default NewsletterDialog;

const query = graphql`
  query SignupNewsletterDialog {
    allPrismicNewsletterSignup {
      edges {
        node {
          id
          lang
          data {
            headline_pre_highlight {
              text
            }
            headline_highlight {
              text
            }
            headline_post_highlight {
              text
            }
            button_text {
              text
            }
            placeholder {
              text
            }
            email_label {
              text
            }
            success_title {
              text
            }
            success_message {
              text
            }
            error_message {
              text
            }
            privacy_notice {
              text
              html
              raw
            }
            marketing_notice {
              text
            }
          }
        }
      }
    }
  }
`;
