import CookieConsent from 'react-cookie-consent';
import React from 'react';
import { useTheme } from 'src/hooks';
import { useStaticQuery, graphql } from 'gatsby';
import { getRelevantNode } from 'src/utils/helpers';
import styled from 'styled-components';

const Text = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.palette.text.primary};

  p {
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 1.2;
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const Headline = styled.h6`
  font-size: 14px;
  margin-bottom: 8px;
`;

const CookieBanner = ({ pageLang }) => {
  const theme = useTheme();
  const cookieBannerData = useStaticQuery(query).cookieBanner;
  const { data } = getRelevantNode(cookieBannerData, pageLang);
  const { headline, text, deny_label, accept_label } = data;

  return (
    <CookieConsent
      style={{
        boxShadow: theme.shadows.card,
        background: '#ffffff',
        opacity: '1',
        fontSize: '10px',
        fontFamily: 'Lato',
        color: theme.palette.text.primary,
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
      }}
      declineButtonStyle={{
        color: '#000',
        fontSize: '12px',
        fontWeight: 'bold',
        padding: '6px 12px',
        background: 'transparent',
      }}
      buttonStyle={{
        color: '#000',
        fontSize: '12px',
        fontWeight: 'bold',
        borderRadius: 4,
        padding: '6px 12px',
        background: theme.palette.primary.main,
        border: 'none',
      }}
      location="bottom"
      buttonText={accept_label.text}
      declineButtonText={deny_label.text}
    >
      <Headline>{headline.text}</Headline>
      <Text dangerouslySetInnerHTML={{ __html: text.html }} />
    </CookieConsent>
  );
};

const query = graphql`
  query CookieQueryLegacy {
    cookieBanner: allPrismicPageNavigation {
      nodes {
        tags
        lang
        data {
          accept_label {
            text
          }
          deny_label {
            text
          }
          functionality_label {
            text
          }
          headline {
            text
          }
          marketing_label {
            text
          }
          necessary_label {
            text
          }
          statistics_label {
            text
          }
          text {
            html
          }
        }
      }
    }
  }
`;

export default CookieBanner;
