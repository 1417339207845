import React, { useRef } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import BackgroundAnalytics from 'src/images/bg_analytics.svg';
import BackgroundCircles from 'src/images/bg_integrations.svg';
import BackgroundTracing from 'src/images/bg_tracing.svg';
import MapComponent from './BackgroundMap';

const Bg = styled.img`
  display: block;
  position: relative;
  overflow: visible;
  z-index: 1;
`;

const BgAnalytics = styled(Bg)`
  top: 40%;
  transform: translateY(-60%) translateX(-60%);
  left: 40%;
  max-width: 200vw;
  ${media.lg`
    top: 75%;
    transform: translateY(-57%) translateX(-40%);
    left: 50%;
    height: 400vh;
    max-width: 90vw;
  `}
`;

const BgCircles = styled(Bg)`
  top: 110%;
  transform: ${({ isLandingIntegration }) =>
    isLandingIntegration
      ? 'translateY(-67%) translateX(-50%)'
      : 'translateY(-63%) translateX(-50%)'};
  left: 50%;
  height: 140vh;
  max-width: 2000vw;
  ${media.lg`
    top: 110%;
    transform: ${({ isLandingIntegration }) =>
      isLandingIntegration
        ? 'translateY(-58%) translateX(-85%)'
        : 'translateY(-55%) translateX(-85%)'};
    left: 100%;
    height: 400vh;
    max-width: 100vw;
  `}
`;

const BgTracing = styled(Bg)`
  top: 50%;
  transform: translateY(-40%) translateX(-25%);
  left: 50%;
  height: 25vh;
  max-width: 100vw;

  ${media.md`
    top: 50%;
    transform: translateY(-40%) translateX(-25%);
    left: 50%;
    height: 45vh;
    max-width: 50vw;
  `}

  ${media.lg`
    top: 50%;
    transform: translateY(-75%) translateX(-50%);
    left: 80%;
    height: 45vh;
    max-width: 50vw;
  `}
  
  ${media.xxl`
    top: 50%;
    transform: translateY(-65%) translateX(-85%);
    left: 80%;
    height: 40vh;
    max-width: 50vw;
  `}
`;

const MapContainerLanding = styled.div`
  position: relative;
  overflow: visible;
  top: 30%;
  left: 50%;
  transform: translateY(-180%) translateX(-22%);
  width: 200vw;
  height: 60%;

  ${media.md`
    top: 35%;
    left: 60%;
    transform: translateY(-120%) translateX(-27%);
    width: 120vw;
    height: 80%;
  `}

  ${media.lg`
    top: 20%;
    left: 70%;
    transform: translateY(-120%) translateX(-58%);
    width: 110vw;
    height: 100%;
  `}

  ${media.xxl`
    top: 20%;
    left: 70%;
    transform: translateY(-120%) translateX(-66%);
    width: 80vw;
    height: 100%;
  `}
`;

const MapContainer = styled.div`
  position: relative;
  overflow: visible;
  top: 25%;
  left: 75%;
  transform: translateY(-40%) translateX(10%);
  width: 20vw;
  height: 50%;

  ${media.md`
    top: 30%;
    left: 60%;
    transform: translateY(-55%) translateX(-30%);
    width: 100vw;
    height: 80%;
  `}

  ${media.lg`
    top: 70%;
    left: 50%;
    transform: translateY(-52%) translateX(-47%);
    width: 100vw;
    height: 100%;
  `}

  ${media.xxl`
    top: 70%;
    left: 50%;
    transform: translateY(-52%) translateX(-50%);
    width: 80vw;
    height: 100%;
  `}
`;

const BackgroundContainer = ({ configuration, cardsIndex }) => {
  const ref = useRef(null);

  switch (configuration) {
    case 'landing_supply_chain':
      return (
        <MapContainerLanding ref={ref}>
          <MapComponent
            configuration={configuration}
            cardsIndex={cardsIndex}
            containerRef={ref}
          />
        </MapContainerLanding>
      );
    case 'product_feature_supply_chain':
      return (
        <MapContainer ref={ref}>
          <MapComponent
            configuration={configuration}
            cardsIndex={cardsIndex}
            containerRef={ref}
          />
        </MapContainer>
      );

    case 'product_feature_analytics_insights':
      return <BgAnalytics className="lazyload" src={BackgroundAnalytics} />;

    case 'landing_trace_origin':
      return <BgTracing className="lazyload" src={BackgroundTracing} />;

    case 'integrations_hero':
      return <BgCircles className="lazyload" src={BackgroundCircles} />;
    case 'landing_integrations':
      return (
        <BgCircles
          className="lazyload"
          src={BackgroundCircles}
          isLandingIntegration
        />
      );

    default:
      return <></>;
  }
};

export default BackgroundContainer;
