import React from 'react';
import styled from 'styled-components';
import Imgix from 'react-imgix';
import 'react-multi-carousel/lib/styles.css';
import { Col, Container as Grid, Row, media } from 'styled-bootstrap-grid';
import { DoubleQuoteSerifLeft as QuotesLeft } from '@styled-icons/open-iconic/DoubleQuoteSerifLeft';

const StyledGrid = styled(Grid)`
  padding: 24px 0px 0px 0px;

  ${media.lg`
    padding: 24px 0px 32px 0px;
  `}
`;

const ImageColumn = styled(Col)`
  position: relative;
  width: 100%;
  height: 150px;
  top: 0;
  left: 0;
  padding: 24px 48px;

  ${media.lg`
    padding: 0px 48px;

    ${({ small }) =>
      small &&
      `
      padding: 0px 16px;
    `}
  `}
`;

const TextColumn = styled(Col)`
  position: relative;
  width: 100%;
  padding: 24px 32px;

  ${media.lg`
    padding: 0px 16px;
  `}
`;

const Image = styled(Imgix)`
  position: absolute;
  z-index: 1;
  top: 0%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 150px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;

  ${media.lg`
    left: 56px;
    transform: translateX(0%);

    ${({ small }) =>
      small &&
      `
      width: 120px;
      height: 120px;
      border-radius: 120px;
      left: 0;
    `}
  `}
`;

const QuoteLeft = styled(QuotesLeft)`
  color: ${({ theme }) => theme.palette.primary.main};
  position: absolute;
  z-index: 3;
  top: 0%;
  width: 50px;
  height: 50px;
  left: 58%;

  ${media.lg`
    left: 164px;

    ${({ small }) =>
      small &&
      `
      left: 95px;
    `}
  `}
`;

const QuoteText = styled.p`
  text-align: left;
  color: ${({ theme, inverted }) =>
    inverted ? '#fff' : theme.palette.text.primary};
`;

const QuotePerson = styled.p`
  text-align: left;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.text.primary};
  color: ${({ theme, inverted }) =>
    inverted ? '#fff' : theme.palette.text.primary};
`;

const Highlight = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
`;

// A summary of the Blog Post
const CustomerQuote = ({
  quote_data: {
    person_image: image,
    person_name: name,
    quote,
    company_position,
  },
  small,
  inverted = false,
}) => {
  return (
    <StyledGrid>
      <Row justifyContent="center" alignItems="center">
        <ImageColumn col={12} lg={4} xl={3} small={small}>
          <Image
            className="lazyload"
            src={image.url}
            htmlAttributes={{
              alt: image.alt,
            }}
            sizes="100vw"
            small={small}
            attributeConfig={{
              src: 'data-src',
              srcSet: 'data-srcset',
              sizes: 'data-sizes',
            }}
          />
          <QuoteLeft small={small} />
        </ImageColumn>
        <TextColumn col={12} lg={8} xl={6}>
          <Row>
            <QuoteText inverted={inverted}>{quote.text}</QuoteText>
          </Row>
          <Row>
            <QuotePerson inverted={inverted}>
              {name.text}
              <span> - </span>
              <Highlight>{company_position.text}</Highlight>
            </QuotePerson>
          </Row>
        </TextColumn>
      </Row>
    </StyledGrid>
  );
};

export default CustomerQuote;
