import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Instagram } from '@styled-icons/entypo-social/Instagram';
import { Linkedin } from '@styled-icons/entypo-social/Linkedin';
import PropTypes from 'prop-types';
import { media } from 'styled-bootstrap-grid';

const StyledInstagram = styled(Instagram)`
  margin-bottom: 3.5px;
`;

const StyledLinkedIn = styled(Linkedin)`
  margin-bottom: 3.5px;
`;

const socialIconMap = {
  instagram: StyledInstagram,
  linkedin: StyledLinkedIn,
};

const FooterLink = styled(Link)`
  color: ${({ theme }) => theme.palette.grey[700]};
  padding: 0px 8px;
  text-decoration: none !important;
  font-weight: 400;
  font-size: 14px;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const IconLink = styled(FooterLink)`
  color: ${({ theme }) => theme.palette.secondary.main};
  padding: 0 4px;
  &:first-of-type {
    padding-left: 16px;
  }
  &:last-of-type {
    padding-right: 0px;
  }
`;

const IconLinks = styled.div`
  display: flex;
  justify-content: center;
`;

const Circle = styled.div`
  border: ${({ smallIcons }) => (smallIcons ? '1.5px' : '4px')} solid !important;
  padding: ${({ smallIcons }) => (smallIcons ? '2.5px 5px' : '18px 20px')};
  margin: ${({ smallIcons }) => (smallIcons ? '3px' : '10px 20px')};
  border-radius: 50%;

  ${media.lg`
    margin: ${({ smallIcons }) => (smallIcons ? '3px' : '10px 60px')};

  `}
`;

const SocialIcons = ({ socialLinks, size = 15 }) => {
  const smallIcons = size < 20;

  return (
    <IconLinks>
      {socialLinks.map(({ social_network, social_page }, i) => {
        const Icon = socialIconMap[social_network];

        return (
          <IconLink key={i + 'socialLink'} to={social_page.url} target="_blank">
            <Circle smallIcons={smallIcons}>
              <Icon size={size} />
            </Circle>
          </IconLink>
        );
      })}
    </IconLinks>
  );
};

SocialIcons.propTypes = {
  socialLinks: PropTypes.array,
  size: PropTypes.number,
};

export default SocialIcons;
