import React from 'react';
import 'lazysizes';
import { ThemeProvider } from 'styled-components';
import { theme, gridTheme, GlobalStyle } from 'src/utils/theme';
import { GridThemeProvider } from 'styled-bootstrap-grid';
import {
  RequestDemoProvider,
  MessageFormProvider,
  RequestDownloadProvider,
} from './src/components/Contexts';

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <GridThemeProvider gridTheme={gridTheme}>
        <RequestDemoProvider>
          <MessageFormProvider>
            <RequestDownloadProvider>
              <GlobalStyle />
              {element}
            </RequestDownloadProvider>
          </MessageFormProvider>
        </RequestDemoProvider>
      </GridThemeProvider>
    </ThemeProvider>
  );
};
