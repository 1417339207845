import React from 'react';
import { Row, Col, Container as Grid, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Imgix from 'react-imgix';
import { ReadMoreButton } from 'components/Shared';
import { DateTime } from 'luxon';
import { Link } from 'gatsby';

const Container = styled.section`
  position: relative;
  overflow: hidden;
  padding: 80px 0;
  ${media.lg`
    padding: 120px 0 56px 0px;
  `}
  background-color: ${({ theme }) => theme.palette.success.light};
`;

const ImageLink = styled(Link)`
  display: block;
`;

const Image = styled(Imgix)`
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.card2};
  margin-bottom: 16px;

  ${media.lg`
    margin-bottom: 0;
  `}
`;

const StyledGrid = styled(Grid)`
  position: relative;
  z-index: 1;
`;

export const HeroDate = styled.p`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.grey[500]};
  text-transform: uppercase;
  margin-bottom: 4px;
`;

const TextContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 0px 24px;
`;

const HeroTitle = styled.h1`
  color: ${({ theme }) => theme.palette.text.primary};
  text-align: left;
  padding: 32px 0px;
`;

const HeroSubtitle = styled.p`
  color: ${({ theme }) => theme.palette.text.primary};
  text-align: left;
`;

const StyledButton = styled(ReadMoreButton)`
  align-self: flex-start;
  margin-top: 8px;
  width: 150px;
`;

const FeaturedPost = ({
  post: { data, uid, url, first_publication_date },
  pageLang,
}) => {
  const { featured_image, title, subtitle } = data;
  const isGerman = pageLang === 'de-de';
  const date = DateTime.fromISO(first_publication_date);

  return (
    <Container>
      <StyledGrid>
        <Row>
          <Col col={12} lg={6} alignItems="stretch" justifyContent="center">
            <ImageLink to={`${url}`}>
              <Image
                className="lazyload"
                src={featured_image?.url}
                sizes="100vw"
                attributeConfig={{
                  src: 'data-src',
                  srcSet: 'data-srcset',
                  sizes: 'data-sizes',
                }}
              />
            </ImageLink>
          </Col>
          <Col col={12} lg={6} alignItems="stretch" justifyContent="center">
            <TextContainer>
              <HeroDate>
                {date
                  .setLocale(isGerman ? 'de' : 'en')
                  .toLocaleString(DateTime.DATE_FULL)}
              </HeroDate>
              <HeroTitle>{title?.text}</HeroTitle>
              <HeroSubtitle>{subtitle?.text}</HeroSubtitle>
              <StyledButton
                to={`${url}`}
                buttonText={isGerman ? 'Mehr erfahren' : 'Read More'}
              />
            </TextContainer>
          </Col>
        </Row>
      </StyledGrid>
    </Container>
  );
};

export default FeaturedPost;
