import React from 'react';
import styled from 'styled-components';
import { Row, media, Container as Grid, Col } from 'styled-bootstrap-grid';
import { HighlightedHeadline } from 'components/Shared';
import { SliceContainer, SliceCaption, ContentGrid } from './NamedSlice';

const StyledSliceContainer = styled(SliceContainer)`
  background-color: ${({ theme, icedBackground }) =>
    icedBackground
      ? theme.palette.success.light
      : theme.palette.text.secondary};
  padding: 16px 0px;

  ${media.md`
      padding: ${({ narrowPadding }) =>
        narrowPadding ? '24px 0px' : '64px 0px 64px 0px'}
      
  `}

  ${media.xxl`
      padding: ${({ narrowPadding }) =>
        narrowPadding ? '80px 0px' : '104px 0px'}
  `}
`;

const ContentColumn = styled(Col)`
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  padding: 16px;

  ${media.lg`
      padding: 16px;
  `}
`;

const StyledCaption = styled(SliceCaption)`
  text-align: center;
`;

const PaddedRow = styled(Row)`
  padding: 16px 0px 0px 0px;

  ${media.lg`
     padding: 16px 0px;
  `}
`;

const ColumnHeadline = styled.h4`
  color: ${({ theme }) => theme.palette.text.primary};
  text-align: left;
  padding: 0px 0px 8px 0px;
  margin: 0px;
  ${media.lg`
    margin: 8px 0px;
  `}
`;

const ColumnText = styled.p`
  color: ${({ theme }) => theme.palette.text.primary};
  text-align: left;
  margin: -8px 0px;
  ${media.lg`
    margin: 0px;
  `}
`;

const ColumnContent = ({ item: { title1: title, text } }) => {
  return (
    <Grid>
      {title.text && (
        <PaddedRow alignItems="start" justifyContent="start">
          <ColumnHeadline>{title.text}</ColumnHeadline>
        </PaddedRow>
      )}
      {text.text && (
        <PaddedRow alignItems="start" justifyContent="start">
          <ColumnText>{text.text}</ColumnText>
        </PaddedRow>
      )}
    </Grid>
  );
};

const ThreeColumnsSlice = ({
  data: {
    title1: sliceTitle,
    headline_pre_highlight: preHighlight,
    headline_highlight: highlight,
    headline_post_highlight: postHighlight,
    has_iced_background: hasIcedBackground,
    has_small_padding: hasSmallPadding,
  },
  items,
}) => {
  let justify;
  let columnWidth;
  switch (items.length) {
    case 1:
      columnWidth = 10;
      justify = 'center';
      break;
    case 2:
      columnWidth = 5;
      justify = 'around';
      break;
    case 3:
    default:
      columnWidth = 3.5;
      justify = 'between';
  }

  return (
    <StyledSliceContainer
      icedBackground={hasIcedBackground}
      narrowPadding={hasSmallPadding}
    >
      <ContentGrid>
        {sliceTitle && (
          <Row alignItems="stretch" justifyContent="center">
            <ContentColumn col={12} alignItems="center" justifyContent="center">
              <StyledCaption>{sliceTitle.text}</StyledCaption>
            </ContentColumn>
          </Row>
        )}

        {(preHighlight.text || highlight.text || postHighlight.text) && (
          <Row alignItems="stretch" justifyContent="center">
            <ContentColumn col={12} alignItems="center" justifyContent="center">
              <HighlightedHeadline
                pre={preHighlight}
                highlight={highlight}
                post={postHighlight}
                highlightNewline={false}
              />
            </ContentColumn>
          </Row>
        )}
        <Row alignItems="stretch" justifyContent={justify}>
          {items.map((item, index) => {
            return (
              <ContentColumn col={12} lg={columnWidth} key={index}>
                <ColumnContent item={item} />
              </ContentColumn>
            );
          })}
        </Row>
      </ContentGrid>
    </StyledSliceContainer>
  );
};

export default ThreeColumnsSlice;
