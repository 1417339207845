import React, { useState, Fragment } from 'react';
import HighlightedHeadline from './HighlightedHeadline';
import styled from 'styled-components';
import { Row, Col, media } from 'styled-bootstrap-grid';
import { useForm } from 'react-hook-form';
import { ErrorMessage, Input, Label, FormControl } from './styles';
import LoadingButton from './LoadingButton';
import { ReadMoreButton, PrimaryButton } from './Buttons';
import RichText from './RichText';
import axios from 'axios';
import { useRequestDemo } from 'src/hooks';

const Container = styled.div`
  padding: 10px 16px 0 16px;
  color: #fff;
  margin: 0 auto;

  p {
    color: #fff;
    font-weight: 400;
  }

  ${media.md`
    width: 500px;
    padding: 160px 40px;
  `}
`;

const FormContainer = styled.div`
  ${media.md`
    width: 80%;
  `}
`;

const Notice = styled.div`
  p {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 0;
    cursor: pointer;
  }

  a {
    color: #fff;
  }
`;

const SignupButton = styled(LoadingButton)`
  margin-bottom: 16px;
`;

const AfterSubmitContainer = styled.div`
  text-align: center;

  p {
    font-size: 14px;
    font-weight: 400;
    color: #000;
  }
`;

const Highlight = styled.p`
  color: ${({ theme }) => theme.palette.primary.main} !important;
`;

const getSubmitURL = (formId) =>
  `https://api.hsforms.com/submissions/v3/integration/submit/5223531/${formId}`;

const SignupForm = ({ data, pageLang = 'en-us' }) => {
  const { register, handleSubmit, errors, formState, setValue, getValues } =
    useForm();
  const [hasSuccess, setSucces] = useState(false);
  const [error, setError] = useState(false);
  const { handleOpenScheduling } = useRequestDemo();

  const handleAccept = (evt) => {
    if (evt?.target?.tagName === 'A') return;
    setValue('privacyAccepted', !getValues('privacyAccepted'));
  };

  const {
    title,
    title_highlight,
    subtitle,
    show_email_field,
    label_email,
    privacy_notice,
    show_company_field,
    label_company,
    show_name_field,
    label_first_name,
    label_last_name,
    submit_label,
    success,
    success_highlight,
    appointment_button_text,
    back_link_label,
    back_link,
    name_field_is_required,
    hubspot_form_id,
  } = data;

  const onSubmit = async (values) => {
    if (hasSuccess) return;
    try {
      await axios.post(getSubmitURL(hubspot_form_id.text), {
        ...valuesToHubspotBody({
          values,
          lang: pageLang,
          privacyText: privacy_notice.text,
        }),
      });

      setSucces(true);
    } catch (err) {
      console.error(err);
      setError(true);
    }
  };

  const disabled = formState.isSubmitting || hasSuccess;

  return (
    <Container>
      <HighlightedHeadline
        pre={title}
        highlight={title_highlight}
        hasDarkBackground
        size="small"
      />
      {subtitle && <p>{subtitle.text}</p>}
      <FormContainer>
        {error && (
          <AfterSubmitContainer>
            <p>Something went wrong, please reload and try again.</p>
          </AfterSubmitContainer>
        )}
        {!error && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              {show_name_field && (
                <Fragment>
                  <Col col={6}>
                    <FormControl>
                      <Label>{label_first_name.text}</Label>
                      <Input
                        ref={register({
                          required: name_field_is_required ? 'Required' : false,
                        })}
                        error={errors.firstName}
                        type="text"
                        name="firstName"
                        disabled={disabled}
                        placeholder="Jane"
                      />
                      {errors.firstName && (
                        <ErrorMessage>{errors.firstName.message}</ErrorMessage>
                      )}
                    </FormControl>
                  </Col>
                  <Col col={6}>
                    <FormControl>
                      <Label>{label_last_name.text}</Label>
                      <Input
                        ref={register({
                          required: name_field_is_required ? 'Required' : false,
                        })}
                        error={errors.lastName}
                        type="text"
                        name="lastName"
                        disabled={disabled}
                        placeholder="Doe"
                      />
                      {errors.lastName && (
                        <ErrorMessage>{errors.lastName?.message}</ErrorMessage>
                      )}
                    </FormControl>
                  </Col>
                </Fragment>
              )}
              {show_company_field && (
                <Col col={12}>
                  <FormControl>
                    <Label>{label_company.text}</Label>
                    <Input
                      ref={register({ required: 'Required' })}
                      error={errors.company}
                      type="text"
                      name="company"
                      disabled={disabled}
                      placeholder="Transparency Inc."
                    />
                    {errors.company && (
                      <ErrorMessage>{errors.company?.message}</ErrorMessage>
                    )}
                  </FormControl>
                </Col>
              )}
              {show_email_field && (
                <Col col={12}>
                  <FormControl>
                    <Label>{label_email.text}</Label>
                    <Input
                      ref={register({
                        required: 'Required',
                        pattern: {
                          value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                          message: 'Please enter a valid email',
                        },
                      })}
                      error={errors.email}
                      type="email"
                      name="email"
                      disabled={disabled}
                      placeholder="jane.doe@example.com"
                    />
                    {errors.email && (
                      <ErrorMessage>{errors.email.message}</ErrorMessage>
                    )}
                  </FormControl>
                </Col>
              )}
              <Col col={12}>
                <FormControl type="checkbox">
                  <input
                    name="privacyAccepted"
                    ref={register({
                      required: 'Please agree to our privacy notice',
                    })}
                    type="checkbox"
                    disabled={disabled}
                  />
                  <Notice onClick={handleAccept}>
                    <RichText render={privacy_notice.raw} />
                  </Notice>
                  {errors.privacyAccepted && (
                    <ErrorMessage>
                      {errors.privacyAccepted.message}
                    </ErrorMessage>
                  )}
                </FormControl>
              </Col>
              <Col col={12}>
                <SignupButton
                  success={hasSuccess}
                  loading={formState.isSubmitting}
                  type="submit"
                >
                  {submit_label.text}
                </SignupButton>
              </Col>
              {hasSuccess && (
                <Col col={12}>
                  {success_highlight && (
                    <Highlight>{success_highlight.text}</Highlight>
                  )}
                  {success && <p>{success.text}</p>}
                  {appointment_button_text && (
                    <PrimaryButton
                      type="button"
                      onClick={() => handleOpenScheduling()}
                      className="plausible-event-name=Book+Demo+Button+Click"
                    >
                      {appointment_button_text.text}
                    </PrimaryButton>
                  )}
                  {back_link_label && (
                    <ReadMoreButton
                      inverted
                      buttonText={back_link_label.text}
                      to={back_link.url}
                    />
                  )}
                </Col>
              )}
            </Row>
          </form>
        )}
      </FormContainer>
    </Container>
  );
};

const valuesToHubspotBody = ({ values, privacyText, lang = '' }) => {
  const fields = ['firstName', 'lastName', 'company', 'email']
    .filter((fieldName) => values[fieldName])
    .map((fieldName) => ({
      name: fieldName,
      value: values[fieldName],
    }));

  return {
    fields: [
      ...fields,
      {
        name: 'language',
        value: lang.substr(0, 2),
      },
    ],
    context: {
      pageUri: window.location.pathname,
      pageName: 'seedtrace website',
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: values.privacyAccepted,
        text: privacyText,
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text: 'Marketing accepted',
          },
        ],
      },
    },
  };
};

export default SignupForm;
