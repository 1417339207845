import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import i18n from 'src/config/i18n';
import { DropDown } from 'components/Shared';
import { navigate } from 'gatsby';
import britishFlag from 'src/images/british_flag.png';
import germanFlag from 'src/images/german_flag.png';
import { useMatch } from '@reach/router';

const ContainerDesktop = styled.div`
  width: 70px;
  margin: 14px 0px 16px 0px;
  display: none;
  ${media.lg`
      display: block;
  `}
`;

const ContainerMobile = styled.div`
  width: 100%;
  padding: 24px 24px;
  display: flex;
  flex-direction: row;

  ${media.lg`
      display: none;
  `}
`;

const DropDownItem = styled.div`
  display: flex;
  img {
    width: 20px;
    height: 15px;
    margin-right: 8px;
  }
`;

const MobileItem = styled.div`
  margin: 0px 64px 0px 0px;
  padding: 8px;
  display: flex;
  border: ${({ theme }) => `1px solid ${theme.palette.success.light}`};
  font-family: Lato;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 6px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  ${({ active, theme }) =>
    active &&
    `
     border:  2px solid ${theme.palette.primary.main};
  `}

  img {
    width: 20px;
    height: 100%;
    margin-right: 8px;

    p {
      padding: 0px;
      line-height: 1;
    }
  }
`;

const Flag = styled.img`
  border-radius: 4px;
  width: 32px;
  height: 20px;
`;

const Label = styled.p`
  padding: 2px 0px 0px 0px;
  margin: 0px;
  font-family: Lato;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.palette.text.primary};
  text-transform: uppercase;
  text-decoration: none;
  line-height: 0.8;
`;

const options = Object.entries(i18n).map(([key, { locale, label, path }]) => ({
  value: locale,
  label,
  path,
}));

const LanguageSwitch = ({ languages, currentLang }) => {
  const { locale, default: isDefault } = i18n[currentLang];
  const matchBlog = useMatch(
    isDefault ? '/blog/:item' : `/${locale}/blog/:item`
  );
  const matchPress = useMatch(
    isDefault ? '/press/:item' : `/${locale}/press/:item`
  );
  const currentValue = {
    value: currentLang,
    label: i18n[currentLang].label + ' ',
    path: i18n[currentLang].path,
  };
  const handleRouteChange = ({ value }) => {
    if (value === currentLang) return;
    const { uid, lang } = languages.find(({ lang }) => lang === value);
    const { locale, default: isDefault } = i18n[lang];

    if (!!matchBlog) {
      if (isDefault) return navigate(`/blog/${uid}`);

      return navigate(`/${locale}/blog/${uid}`);
    }

    if (!!matchPress) {
      if (isDefault) return navigate(`/press/${uid}`);

      return navigate(`/${locale}/press/${uid}`);
    }

    if (isDefault) navigate(`/${uid}`);
    else navigate(`/${locale}/${uid}`);
  };

  return (
    <div>
      <ContainerDesktop className="hiddenLgDown">
        <DropDown
          currentValue={currentValue}
          onChange={handleRouteChange}
          options={options}
          renderItems={(option) => {
            return (
              <DropDownItem>
                <Flag
                  src={option.value === 'en-us' ? britishFlag : germanFlag}
                  alt={`${option.label} flag`}
                />
                <Label> {option.path}</Label>
              </DropDownItem>
            );
          }}
        />
      </ContainerDesktop>
      <ContainerMobile className="hiddenSmUp">
        {options.map((option, index) => (
          <MobileItem
            key={index}
            active={option.value === currentLang}
            onClick={() => handleRouteChange(option)}
          >
            <Flag
              src={option.value === 'en-us' ? britishFlag : germanFlag}
              alt={`${option.label} flag`}
            />
            <Label> {option.path}</Label>
          </MobileItem>
        ))}
      </ContainerMobile>
    </div>
  );
};

export default LanguageSwitch;
