import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { useSpring, animated, config } from 'react-spring';

const FadeIn = ({ children }) => {
  const props = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 100,
    config: { ...config.stiff },
  });
  return <animated.div style={props}>{children}</animated.div>;
};

const FadeOnSlide = ({ children }) => {
  const [isVisible, setVisibility] = useState(false);
  const onChange = (visiblity) => {
    visiblity && setVisibility(visiblity);
  };

  return (
    <VisibilitySensor partialVisibility onChange={onChange}>
      <FadeIn isVisible={isVisible}>{children}</FadeIn>
    </VisibilitySensor>
  );
};

export default FadeOnSlide;
