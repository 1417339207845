import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import Imgix from 'react-imgix';
import { useBreakpoint } from 'src/hooks';
import { ChevronLeft, ChevronRight } from '@styled-icons/bootstrap';

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  height: 200px;

  ${media.sm`
     height: 240px;
  `}

  ${media.md`
     height: 280px;
  `}

  ${media.lg`
     height: 316px;
     padding-top: 48px;
  `}
  
  ${media.xl`
     height: 364px;
     padding-top: 48px;
  `}
`;

const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin: ${({ active }) => (active ? '0px 16px' : '0px 8px')};
  background-color: ${({ theme }) => theme.palette.text.secondary};
  box-shadow: 4px 4px 24px 10px #00000014;
  transition: height 0.5s ease-in-out, width 0.5s ease-in-out;
  height: ${({ active }) => (active ? '100%' : '40%')};
  width: ${({ active }) => (active ? '45%' : '22.5%')};
`;

const CardImageContainer = styled.div`
  position: relative;
  top: 0px;
  width: 100%;
  height: 48%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${({ theme }) => theme.palette.success.light};
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
`;

const CardImage = styled(Imgix)`
  opacity: ${({ active }) => (active ? 1 : 0)};
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  height: 100%;
  display: block;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  transition: opacity 0.45s ease-in-out;
  transition-delay: 0.4s;
  opacity: ${({ active }) => (active ? 1 : 0)};
`;

const CardContent = styled.div`
  position: relative;
  height: 100%;
  margin: 8px 8px;
  ${media.sm`
    margin: ${({ active }) => (active ? '16px' : '8px')};
  `}
`;

const ActiveContent = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  transition: opacity 0.45s ease-in-out;
  transition-delay: 0.4s;
  opacity: ${({ active }) => (active ? 1 : 0)};
`;

const InactiveContent = styled(ActiveContent)`
  visibility: ${({ inactive }) => (inactive ? 'visible' : 'hidden')};
  transition: opacity 0.45s ease-in-out;
  transition-delay: 0.4s;
  opacity: ${({ inactive }) => (inactive ? 1 : 0)};
  cursor: pointer;
`;

const PlaceholderRow = styled.div`
  position: relative;
  left: 0%;
  height: ${({ active }) => (active ? '8' : '12')}%;
  width: ${({ rowWidth }) => rowWidth}%;
  margin: ${({ active }) => (active ? '8px 0px' : '4px 0px')};
  border-radius: 25px;
  background-color: ${({ theme }) => theme.palette.grey[200]};
`;

const CardTitleLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
`;

const IndexCircle = styled.div`
  border-radius: 25px;
  box-shadow: 0px 2px 16px 0px #0000001f;
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 17px;
  height: 17px;
  text-align: center;
  display: block;
  ${media.sm`
    width: 24px;
    height: 24px;
  `}

  ${media.md`
    width: 34px;
    height: 34px;
  `}
`;

const IndexCircleInactive = styled(IndexCircle)`
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: ${({ theme }) => theme.palette.text.secondary};
  z-index: 10;
`;

const ArrowCircleLeft = styled(IndexCircle)`
  position: absolute;
  top: 20%;
  left: 0%;
  transform: translateX(-150%);
  background-color: ${({ theme }) => theme.palette.grey[600]};
  color: ${({ theme }) => theme.palette.text.secondary};
  opacity: ${({ active }) => (active ? 1 : 0)};
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  transition: opacity 0.45s ease-in-out;
  transition-delay: 0.4s;
  cursor: pointer;
  z-index: 10;
  display: none;
  ${media.sm`
    display: block;
    width: 28px;
    height: 28px;
  `}

  ${media.md`
    width: 34px;
    height: 34px;
  `}

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[500]};
  }
`;

const ArrowCircleRight = styled(IndexCircle)`
  position: absolute;
  top: 20%;
  right: 0%;
  transform: translateX(150%);
  background-color: ${({ theme }) => theme.palette.grey[600]};
  color: ${({ theme }) => theme.palette.text.secondary};
  opacity: ${({ active }) => (active ? 1 : 0)};
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  transition: opacity 0.45s ease-in-out;
  transition-delay: 0.4s;
  cursor: pointer;
  z-index: 10;
  display: none;
  ${media.sm`
    display: block;
    width: 28px;
    height: 28px;
  `}

  ${media.md`
    width: 34px;
    height: 34px;
  `}
  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[500]};
  }
`;

const IndexNumber = styled.p`
  font-family: Lato;
  font-weight: 900;
  color: ${({ theme, active }) =>
    active ? theme.palette.text.secondary : theme.palette.grey[600]};
  font-size: 10px;
  padding: 0px;
  margin: 0px;
  line-height: 1.6;
  ${media.sm`
    font-size: 12px;
    line-height: 2;
  `}
  ${media.md`
    font-size: 16px;
    line-height: 2;
  `}
`;

const StyledChevronLeft = styled(ChevronLeft)`
  position: relative;
  top: 40%;
  left: 0%;
  transform: translateY(-80%);
  ${media.sm`
     transform: translateY(-50%);
  `}
`;

const StyledChevronRight = styled(ChevronRight)`
  position: relative;
  top: 40%;
  left: 0%;
  transform: translateY(-80%);
  ${media.sm`
     transform: translateY(-50%);
  `}
`;

const CardTitle = styled.p`
  font-family: Montserrat;
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 700;
  text-align: left;
  font-size: 10px;
  padding-left: 8px;
  margin: 0px;
  ${media.md`
    font-size: 16px;
    padding-left: 16px;
  `}
`;

const CardItem = ({
  image,
  index,
  activeIndex,
  onChange,
  itemCount,
  imageTitle,
}) => {
  const isActive = index === activeIndex ? true : false;
  const isFirst = index === 0 ? true : false;
  const isLast = index === itemCount - 1 ? true : false;

  const chevronSize = useBreakpoint('sm') ? 12 : 18;

  return (
    <CardContainer active={isActive}>
      <CardImageContainer
        active={isActive}
        onClick={() => !isActive && onChange(index)}
      >
        <CardImage
          active={isActive}
          className="lazyload"
          src={image.url}
          htmlAttributes={{
            alt: image.alt,
          }}
          sizes="50vw"
          attributeConfig={{
            src: 'data-src',
            srcSet: 'data-srcset',
            sizes: 'data-sizes',
          }}
        />
      </CardImageContainer>
      <CardContent active={isActive}>
        <ActiveContent active={isActive}>
          <CardTitleLine>
            <IndexCircle>
              <IndexNumber active={isActive}>{index + 1}</IndexNumber>
            </IndexCircle>
            <CardTitle>{imageTitle}</CardTitle>
          </CardTitleLine>
          <PlaceholderRow rowWidth={30} active={isActive} />
          <PlaceholderRow rowWidth={90} active={isActive} />
          <PlaceholderRow rowWidth={70} active={isActive} />
        </ActiveContent>
        <InactiveContent inactive={!isActive} onClick={() => onChange(index)}>
          <PlaceholderRow rowWidth={30} active={isActive} />
          <PlaceholderRow rowWidth={90} active={isActive} />
          <PlaceholderRow rowWidth={70} active={isActive} />
        </InactiveContent>
      </CardContent>

      {!isActive && (
        <IndexCircleInactive>
          <IndexNumber active={isActive}>{index + 1}</IndexNumber>
        </IndexCircleInactive>
      )}

      {!isFirst && (
        <ArrowCircleLeft active={isActive} onClick={() => onChange(index - 1)}>
          <StyledChevronLeft size={chevronSize} />
        </ArrowCircleLeft>
      )}

      {!isLast && (
        <ArrowCircleRight active={isActive} onClick={() => onChange(index + 1)}>
          <StyledChevronRight size={chevronSize} />
        </ArrowCircleRight>
      )}
    </CardContainer>
  );
};

const CardsCarousel = ({ images, onChange, activeIndex }) => {
  return (
    <CarouselContainer>
      {images.map(({ image, image_title: imageTitle }, index) => {
        return (
          <CardItem
            key={index}
            image={image}
            index={index}
            activeIndex={activeIndex}
            onChange={onChange}
            itemCount={images.length}
            imageTitle={imageTitle?.text}
          />
        );
      })}
    </CarouselContainer>
  );
};

export default CardsCarousel;
