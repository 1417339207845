import React from 'react';
import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
import Imgix from 'react-imgix';
import { useTheme } from 'src/hooks';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 120px;
  background: #fff;
  z-index: 5;
  border: 2px solid #f4f5fa;
  margin-bottom: 3rem;
`;

const StyledImage = styled(Imgix)`
  object-fit: scale-down;
  z-index: 1;
  height: 100%;
  max-width: 120px;
  padding: 10px;
`;

const ImageContainer = styled.div`
  height: 120px;
`;

const LogoBanner = ({ items }) => {
  const { breakpoints } = useTheme();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
    },
    tablet: {
      breakpoint: { max: 1024, min: breakpoints.sm },
      items: 5,
    },
    mobile: {
      breakpoint: { max: breakpoints.sm, min: 0 },
      items: 3,
    },
  };
  return (
    <Container>
      <Carousel
        removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
        responsive={responsive}
        ssr
        showDots={false}
        autoPlay
        autoPlaySpeed={1}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        slidesToSlide={2}
        transitionDuration={23000}
        infinite
        customTransition="all 23s linear"
      >
        {items.map(({ company_logo }) => {
          return (
            <ImageContainer key={company_logo.url}>
              <StyledImage
                className="lazyload"
                src={company_logo.url}
                htmlAttributes={{ alt: company_logo.alt }}
                attributeConfig={{
                  src: 'data-src',
                  srcSet: 'data-srcset',
                  sizes: 'data-sizes',
                }}
              />
            </ImageContainer>
          );
        })}
      </Carousel>
    </Container>
  );
};

export default LogoBanner;
