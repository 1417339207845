import React from 'react';
import styled from 'styled-components';
import { Row, Container as Grid, Col } from 'styled-bootstrap-grid';
import { FactCard, CustomerQuote } from 'components/Shared';
import { SliceContainer, BackgroundContent } from './NamedSlice';

const ContentColumn = styled(Col)`
  height: 100%;
  text-align: start;
  padding: 16px;
`;

const StyledContainer = styled(SliceContainer)``;

const StandaloneItemsSlice = ({ items, data }) => {
  // Assuming all items are of same type
  let docType = items[0].item.document.type;
  const renderBackground = data?.render_on_homepage;
  return (
    <SliceContainer narrowPadding={true}>
      <Grid>
        <Row
          alignItems="stretch"
          justifyContent={docType === 'numeric_fact' ? 'around' : 'center'}
        >
          {items.map((item, id) => {
            switch (docType) {
              case 'numeric_fact':
                return (
                  <Col col={10} lg={8} xl={2.8} key={id}>
                    <FactCard fact_data={item.item.document.data} />
                  </Col>
                );

              case 'testimonial_quote':
              default:
                return (
                  <ContentColumn
                    col={12}
                    md={9}
                    alignItems="stretch"
                    justifyContent="center"
                    key={id}
                  >
                    <CustomerQuote quote_data={item.item.document.data} />
                  </ContentColumn>
                );
            }
          })}
        </Row>
      </Grid>
      {renderBackground && (
        <BackgroundContent configuration="landing_supply_chain" />
      )}
    </SliceContainer>
  );
};

export default StandaloneItemsSlice;
