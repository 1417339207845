import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import { Row, media, Container as Grid, Col } from 'styled-bootstrap-grid';
import {
  BackgroundDots,
  BackgroundContainer,
  FadeOnSlide,
  ExpertImage,
  HeroImage,
  RevealedImage,
} from 'components/Graphics';
import {
  CardsCarousel,
  SecondaryButton,
  ReadMoreButton,
  HighlightedHeadline,
  VideoDialog,
} from 'components/Shared';
import { useRequestDemo, useRequestDownload } from 'src/hooks';

export const SliceContainer = styled.div`
  position: relative;
  z-index: ${({ hasDarkBackground, renderOnTop }) =>
    hasDarkBackground || renderOnTop ? 5 : 1};
  text-align: left;
  justify-content: center;
  overflow: visible;
  background-color: ${({ theme, hasDarkBackground }) =>
    hasDarkBackground ? theme.palette.success.light : 'transparent'};
  padding: 16px 0px;

  .desktop-slider {
    margin: 0 auto;
    max-width: 1500px;

    .react-multi-carousel-list {
      padding-top: 20px;
    }
  }

  ${media.md`
      padding: ${({ narrowPadding, noPaddingBottom }) =>
        narrowPadding
          ? '0px 0px'
          : noPaddingBottom
          ? '64px 0px 0px 0px'
          : '64px 0px 64px 0px'}
      
  `}
  ${media.xxl`
      padding: ${({ narrowPadding, noPaddingBottom }) =>
        narrowPadding
          ? '24px 0px'
          : noPaddingBottom
          ? '104px 0px 0px'
          : '104px 0px'}
  `}
`;

export const ContentGrid = styled(Grid)`
  position: relative;
  z-index: 10;
  ${media.xl`
        padding: 0px 88px;
  `}
`;

export const ContentColumn = styled(Col)`
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  text-align: ${({ centerText }) => (centerText ? 'center' : 'start')};
  overflow: visible;
  padding: 16px;
  ${media.lg`
        padding: 32px 16px;
  `}
`;

const ImageColumn = styled(Col)`
  overflow: visible;
`;

export const SliceCaption = styled.h3`
  margin: 8px 0px;
  color: ${({ theme }) => theme.palette.secondary.dark};
  text-align: ${({ alignCenter }) => (alignCenter ? 'center' : 'unset')};

  ${media.lg`
    margin: 0px;
  `}
`;

export const SliceText = styled.p`
  padding: 16px 0px 32px 0px;
  margin: 0px;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  padding: 0px 0px 8px 0px;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  margin-right: 32px;

  &:last-child {
    margin-right: 0;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : 0)}px;
`;

const Container = styled.div`
  z-index: -20;
  display: block;
  position: absolute;
  overflow: visible;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const HiddenOnMobile = styled.div`
  display: none;
  z-index: 3;
  ${media.lg`
    display: block;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%
  `}
`;

export const BackgroundContent = ({ configuration, cardsIndex }) => {
  const slicesWithBackground = [
    'integrations_hero',
    'landing_integrations',
    'landing_analytics_insights',
    'landing_trace_origin',
    'product_feature_analytics_insights',
    'landing_supply_chain',
    'product_feature_supply_chain',
  ];
  if (slicesWithBackground.includes(configuration)) {
    return (
      <Container>
        <BackgroundContainer
          configuration={configuration}
          cardsIndex={cardsIndex}
        />
      </Container>
    );
  } else {
    return <></>;
  }
};

const Dots = ({ configuration }) => {
  const slicesWithDots = [
    'product_feature_demonstrate_impact',
    'product_feature_trace_origin',
    'product_feature_analytics_insights',
    'landing_demonstrate_impact',
    'landing_trace_origin',
    'press_headline',
  ];

  if (slicesWithDots.includes(configuration)) {
    return (
      <HiddenOnMobile>
        <BackgroundDots configuration={configuration} />
      </HiddenOnMobile>
    );
  } else {
    return <></>;
  }
};

const ImageContent = ({
  images,
  invertOrder,
  configuration,
  setCardsIndex,
  cardsIndex,
  openVideoDialog,
}) => {
  switch (configuration) {
    case 'landing_hero':
      return (
        <ImageContainer>
          <HeroImage images={images} openVideoDialog={openVideoDialog} />
        </ImageContainer>
      );
    case 'landing_page_simple':
      return (
        <ImageContainer>
          <RevealedImage images={images} />
        </ImageContainer>
      );
    case 'integrations_expert':
      return (
        <ImageContainer>
          <ExpertImage imageData={images[0].image} />
        </ImageContainer>
      );
    case 'landing_supply_chain':
      return (
        <ImageContainer>
          <CustomInBetweenSpacing />
          <CardsCarousel
            images={images}
            onChange={setCardsIndex}
            activeIndex={cardsIndex}
          />
        </ImageContainer>
      );

    case 'product_feature_supply_chain':
      return (
        <ImageContainer>
          <CustomProductSpacing />
          <CardsCarousel
            images={images}
            onChange={setCardsIndex}
            activeIndex={cardsIndex}
          />
        </ImageContainer>
      );

    case 'product_feature':
      return (
        <ImageContainer marginTop={26}>
          <RevealedImage invertOrder={invertOrder} images={images} />
        </ImageContainer>
      );

    case 'integrations_hero':
    case 'landing_integrations':
      return <></>;

    case 'case_study_koa':
    case 'case_study_conflictfood':
    case 'landing_demonstrate_impact':
    case 'landing_analytics_insights':
    case 'landing_trace_origin':
    case 'product_feature_demonstrate_impact':
    case 'product_feature_analytics_insights':
    case 'product_feature_trace_origin':
    case 'to_right_border':
    default:
      if (images.length === 0) {
        return <></>;
      }
      return (
        <ImageContainer>
          <RevealedImage invertOrder={invertOrder} images={images} />
        </ImageContainer>
      );
  }
};

const CustomTopSpacing = styled.div`
  display: ${({ renderSpacing }) => (renderSpacing ? 'block' : 'none')};
  height: 0px;
  ${media.lg`
      margin-top: -40px;
  `}

  ${media.xxl`
      margin-top: -80px;
  `}
`;

const CustomInBetweenSpacing = styled.div`
  display: block;
  width: 100%;
  height: 200px;

  ${media.md`
    height: 280px;
  `}

  ${media.lg`
      height: 0px;
  `}
`;

const CustomProductSpacing = styled.div`
  display: block;
  width: 100%;
  height: 20px;

  ${media.md`
    height: 40px;
  `}
`;

const CustomBottomSpacingIntegrations = styled.div`
  display: ${({ renderSpacing }) => (renderSpacing ? 'block' : 'none')};
  height: 30vh;
  ${media.lg`
      height: ${({ isLandingIntegration }) =>
        isLandingIntegration ? '20vh' : '0px'};;  
  `}
`;

const CustomBottomSpacingCarousel = styled.div`
  display: ${({ renderSpacing }) => (renderSpacing ? 'block' : 'none')};
  height: 0vh;
  ${media.lg`
      height: 8vh;  
  `}
`;

const NamedSlice = ({
  data: {
    slice_title: sliceTitle,
    headline_pre_highlight: preHighlight,
    headline_highlight: highlight,
    headline_post_highlight: postHighlight,
    highlight_newline: highlightNewline,
    text,
    button_text: buttonText,
    button_text_demo: buttonTextDemo,
    open_hubspot_dialog: openHubspotDialog,
    button_text_secondary: buttonTextSecondary,
    navigation_link: navigationLink,
    navigation_link_secondary: navigationLinkSecondary,
    image_configuration: imageConfiguration,
    invert_order: invertOrder,
    has_iced_background: hasIcedBackground,
    has_narrow_text_column: hasNarrowTextColumn,
    has_small_padding: hasSmallPadding,
    has_no_padding_bottom: hasNoPaddingBottom,
    center_text: centerText,
    video_link: videoLink,
    section_title: sectionTitle,
    navigation_link_is_download,
  },
  items: images,
}) => {
  const { openDemoDialog, handleOpenScheduling } = useRequestDemo();
  const { handleOpenDownload } = useRequestDownload();
  const [cardsIndex, setCardsIndex] = useState(0);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const isHero =
    imageConfiguration == 'landing_hero' ||
    imageConfiguration == 'landing_page_simple';
  const isCarouselSlice =
    imageConfiguration == 'landing_supply_chain' ||
    imageConfiguration == 'product_feature_supply_chain';

  const isCarouselSliceLanding = imageConfiguration == 'landing_supply_chain';

  const isIntegrationSlice =
    imageConfiguration == 'landing_integrations' ||
    imageConfiguration == 'integrations_hero';

  const imageWidth = imageConfiguration == 'to_right_border' ? 6 : 5;

  return (
    <Fragment>
      <VideoDialog
        open={isVideoOpen}
        onClose={() => setIsVideoOpen(false)}
        url={videoLink?.url}
      />
      <SliceContainer
        hasDarkBackground={hasIcedBackground}
        renderOnTop={isHero}
        narrowPadding={hasSmallPadding}
        noPaddingBottom={hasNoPaddingBottom}
      >
        <CustomTopSpacing renderSpacing={isCarouselSliceLanding} />
        <ContentGrid>
          {sectionTitle?.text && (
            <SliceCaption alignCenter>{sectionTitle.text}</SliceCaption>
          )}
          <Row
            alignItems={isCarouselSlice ? 'stretch' : 'center'}
            lgAlignItems={isCarouselSlice ? 'stretch' : 'center'}
            justifyContent="between"
          >
            <ContentColumn
              lg={
                !!images?.length > 0 || !!imageConfiguration?.length > 0
                  ? hasNarrowTextColumn
                    ? 5
                    : 6
                  : 12
              }
              lgOrder={invertOrder ? 'last' : 'first'}
              hiddenLgDown
              centerText={!images?.length && !imageConfiguration?.length}
            >
              <FadeOnSlide>
                <SliceCaption>{sliceTitle.text}</SliceCaption>
                <HighlightedHeadline
                  pre={preHighlight}
                  highlight={highlight}
                  post={postHighlight}
                  highlightNewline={highlightNewline}
                />
                <SliceText>{text.text}</SliceText>
                <ButtonContainer
                  center={!images?.length && !imageConfiguration?.length}
                >
                  {buttonText.text &&
                    !buttonTextDemo?.text &&
                    (navigation_link_is_download ? (
                      <StyledSecondaryButton
                        onClick={() => handleOpenDownload(navigationLink?.url)}
                        size="small"
                      >
                        {buttonText.text}
                      </StyledSecondaryButton>
                    ) : (
                      <StyledSecondaryButton
                        to={navigationLink?.url}
                        size="small"
                      >
                        {buttonText.text}
                      </StyledSecondaryButton>
                    ))}

                  {buttonTextDemo?.text && (
                    <StyledSecondaryButton
                      onClick={() =>
                        openHubspotDialog
                          ? handleOpenScheduling()
                          : openDemoDialog()
                      }
                      className="plausible-event-name=Request+Demo+Button+Click"
                      size="small"
                    >
                      {buttonTextDemo.text}
                    </StyledSecondaryButton>
                  )}

                  {buttonTextSecondary.text && (
                    <ReadMoreButton
                      buttonText={buttonTextSecondary.text}
                      to={navigationLinkSecondary.url}
                    />
                  )}
                </ButtonContainer>
              </FadeOnSlide>
            </ContentColumn>

            <ContentColumn col={12} hiddenLgUp>
              <SliceCaption>{sliceTitle.text}</SliceCaption>
              <HighlightedHeadline
                pre={preHighlight}
                highlight={highlight}
                post={postHighlight}
                highlightNewline={highlightNewline}
              />
            </ContentColumn>

            {!!images?.length && (
              <ImageColumn col={12} lg={hasNarrowTextColumn ? 6 : imageWidth}>
                <ImageContent
                  images={images}
                  configuration={imageConfiguration}
                  invertOrder={invertOrder}
                  setCardsIndex={setCardsIndex}
                  cardsIndex={cardsIndex}
                  openVideoDialog={() => setIsVideoOpen(true)}
                />
              </ImageColumn>
            )}

            <ContentColumn col={12} hiddenLgUp>
              <SliceText>{text.text}</SliceText>
              <ButtonContainer
                center={!images?.length && !imageConfiguration?.length}
              >
                {buttonText.text && !buttonTextDemo?.text && (
                  <StyledSecondaryButton to={navigationLink?.url} size="small">
                    {buttonText.text}
                  </StyledSecondaryButton>
                )}

                {buttonTextDemo?.text && (
                  <StyledSecondaryButton
                    onClick={() =>
                      openHubspotDialog
                        ? handleOpenScheduling()
                        : openDemoDialog()
                    }
                    className="plausible-event-name=Request+Demo+Button+Click"
                    size="small"
                  >
                    {buttonTextDemo.text}
                  </StyledSecondaryButton>
                )}

                {buttonTextSecondary.text && (
                  <ReadMoreButton
                    buttonText={buttonTextSecondary.text}
                    to={navigationLinkSecondary.url}
                  />
                )}
              </ButtonContainer>
            </ContentColumn>
          </Row>
        </ContentGrid>
        <CustomBottomSpacingIntegrations
          renderSpacing={isIntegrationSlice}
          isLandingIntegration={imageConfiguration == 'landing_integrations'}
        />
        <CustomBottomSpacingCarousel
          renderSpacing={isCarouselSlice && !isCarouselSliceLanding}
        />
        <BackgroundContent
          configuration={imageConfiguration}
          cardsIndex={cardsIndex}
        />
        <Dots configuration={imageConfiguration} />
      </SliceContainer>
    </Fragment>
  );
};

export default NamedSlice;
