import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import Imgix from 'react-imgix';
import { useSpring, animated, config } from 'react-spring';
import { useBreakpoint } from '../../hooks';

const CanvasContainer = styled.div`
  background-color: transparent;
  max-width: 500px;
  width: 100%;
  height: 332px;
  position: relative;
  margin: 16px 0px;
  ${media.sm`
    height: 444px;
  `}
  ${media.md`
    height: 480px;
  `}
  
  ${media.lg`
    margin: 16px 0px;
  `}
`;

const ImageContainer = styled.div`
  ${({ isImpactClaim, theme }) =>
    isImpactClaim &&
    `
  img {
    box-shadow: ${theme.shadows.card};
    border-radius: 50px;
  }
    
  `}
`;

const AnimatedAnchoredImage = ({ config, image, openVideoDialog }) => {
  const isMobile = useBreakpoint('sm');
  const props = useSpring(config.animation);
  return (
    <ImageContainer
      isImpactClaim={config.isImpactClaim}
      style={config.anchor}
      onClick={config.openVideo && openVideoDialog}
      className="plausible-event-name=Seedtrace+Video+Click"
    >
      <animated.div style={props}>
        <Imgix
          className="lazyload"
          sizes={isMobile ? config.sizeMobile : config.size}
          src={image?.url}
          attributeConfig={{
            src: 'data-src',
            srcSet: 'data-srcset',
            sizes: 'data-sizes',
          }}
          props
          htmlAttributes={{
            alt: image?.alt,
          }}
        />
      </animated.div>
    </ImageContainer>
  );
};

const imageConfigs = {
  0: {
    animation: {
      from: { opacity: 0, transform: 'translateY(-2%) translateX(-55%)' },
      to: { opacity: 0.8, transform: 'translateY(-2%) translateX(-55%)' },
      config: { duration: 500 },
      delay: 500,
    },
    anchor: { position: 'absolute', left: '50%', top: '0%', width: '100%' },
    size: '80vh',
    sizeMobile: '60vh',
    openVideo: false,
    isImpactClaim: false,
  },
  1: {
    animation: {
      from: { opacity: 0, transform: 'translateY(10%) translateX(-90%)' },
      to: { opacity: 0.8, transform: 'translateY(5%) translateX(-90%)' },
      config: { ...config.stiff },
      delay: 1500,
    },
    anchor: { position: 'absolute', left: '100%', top: '0%', width: '50%' },
    size: '40vh',
    sizeMobile: '30vh',
    openVideo: false,
    isImpactClaim: false,
  },
  2: {
    animation: {
      from: { opacity: 0, transform: 'translateY(650%) translateX(150%)' },
      to: { opacity: 1, transform: 'translateY(650%) translateX(-62%)' },
      config: { ...config.stiff },
      delay: 2000,
    },
    anchor: { position: 'absolute', left: '25%', top: '0%', width: '40%' },
    size: '50vh',
    sizeMobile: '30vh',
    openVideo: false,
    isImpactClaim: true,
  },
  3: {
    animation: {
      from: { opacity: 0, transform: 'translateY(650%) translateX(130%)' },
      to: { opacity: 1, transform: 'translateY(650%) translateX(-128%)' },
      config: { ...config.stiff },
      delay: 2300,
    },
    anchor: { position: 'absolute', left: '75%', top: '0%', width: '24.6%' },
    size: '50vh',
    sizeMobile: '30vh',
    openVideo: false,
    isImpactClaim: true,
  },
  4: {
    animation: {
      from: { opacity: 0, transform: 'translateY(750%) translateX(150%)' },
      to: { opacity: 1, transform: 'translateY(765%) translateX(-68%)' },
      config: { ...config.stiff },
      delay: 2600,
    },
    anchor: { position: 'absolute', left: '25%', top: '0%', width: '22.3%' },
    size: '50vh',
    sizeMobile: '30vh',
    openVideo: false,
    isImpactClaim: true,
  },
  5: {
    animation: {
      from: { opacity: 0, transform: 'translateY(750%) translateX(130%)' },
      to: { opacity: 1, transform: 'translateY(765%) translateX(-122%)' },
      config: { ...config.stiff },
      delay: 2900,
    },
    anchor: { position: 'absolute', left: '75%', top: '0%', width: '32.5%' },
    size: '50vh',
    sizeMobile: '30vh',
    openVideo: false,
    isImpactClaim: true,
  },
  // 6: {
  //   animation: {
  //     from: { opacity: 0 },
  //     to: { opacity: 1 },
  //     config: { duration: 500 },
  //     delay: 1500,
  //   },
  //   anchor: {
  //     position: 'absolute',
  //     left: '35%',
  //     top: '30%',
  //     cursor: 'pointer',
  //   },
  //   size: '13vh',
  //   sizeMobile: '10vh',
  //   openVideo: true,
  //   isImpactClaim: false,
  // },
};

const HeroImage = ({ images, openVideoDialog }) => {
  return (
    <CanvasContainer>
      {Object.values(imageConfigs).map((config, index) => {
        return (
          <AnimatedAnchoredImage
            key={index}
            config={config}
            image={images[index]?.image}
            openVideoDialog={openVideoDialog}
          />
        );
      })}
    </CanvasContainer>
  );
};

export default HeroImage;
