import React from 'react';
import PropTypes from 'prop-types';
import {
  BenefitsSlice,
  BrandsSlice,
  NewsSlice,
  TeamSlice,
  CaseStudyTeaserSlice,
  AccordionSlice,
  ContactFormSlice,
  DarkDividerSlice,
  IntegrationsSlice,
  JobsSlice,
  NamedSlice,
  NamedSliceDivided,
  StandaloneItemsSlice,
  TextColumnSlice,
  ThreeColumnsSlice,
  WhySeedtraceSlice,
  SocialMediaHighlight,
  LatestSeedtraceInNewsEnglish,
  LatestSeedtraceInNewsGerman,
  LatestPressReleasesEnglish,
  LatestPressReleasesGerman,
  VerifiedPaymentWidget,
  ProductSliderWidget,
  LogoBanner,
  FourItemsSlice,
  HubspotForm,
  TitleAndDescriptionSlice,
} from 'components/Slices';

const SliceZone = ({ allSlices, id, recentPosts, language }) => {
  if (!allSlices || !allSlices[0]) return null;
  const slices = allSlices.map(({ primary, slice_type, items }, index) => {
    switch (slice_type) {
      // These are the API IDs of the slices
      case 'named_slice':
        return <NamedSlice key={index} data={primary} items={items} />;
      case 'named_slice_divided':
        return <NamedSliceDivided key={index} data={primary} items={items} />;
      case 'dark_divider':
        return <DarkDividerSlice key={index} data={primary} />;
      case 'case_study_teaser':
        return <CaseStudyTeaserSlice key={index} data={primary} />;
      case 'ask_us_anything':
        return <ContactFormSlice key={index} data={primary} />;
      case 'text_columns':
        return <TextColumnSlice key={index} data={primary} items={items} />;
      case 'three_columns':
        return (
          <ThreeColumnsSlice
            key={index}
            data={primary}
            items={items}
            pageLang={language}
          />
        );
      case 'standalone_items':
        return (
          <StandaloneItemsSlice key={index} data={primary} items={items} />
        );
      case 'seedtrace_team':
        return <TeamSlice key={index} data={primary} items={items} />;
      case 'integrations_slice':
        return <IntegrationsSlice key={index} data={primary} items={items} />;
      case 'job_slice':
        return <JobsSlice key={index} data={primary} items={items} />;
      case 'accordion_slice':
        return <AccordionSlice key={index} data={primary} items={items} />;
      case 'benefits_slice':
        return <BenefitsSlice key={index} data={primary} items={items} />;
      case 'news_slice':
        return (
          <NewsSlice
            key={`${slice_type}-${index}`}
            data={primary}
            recentPosts={recentPosts}
          />
        );
      case 'brands':
        return (
          <BrandsSlice
            key={`${slice_type}-${index}`}
            data={primary}
            items={items}
          />
        );
      case 'why_seedtrace':
        return (
          <WhySeedtraceSlice
            key={`${slice_type}-${index}`}
            data={primary}
            items={items}
          />
        );
      case 'social_media_highlight':
        return (
          <SocialMediaHighlight
            key={`${slice_type}-${index}`}
            data={primary}
            items={items}
          />
        );
      case 'verified_payments_widget':
        return (
          <VerifiedPaymentWidget
            key={`${slice_type}-${index}`}
            data={primary}
            language={language}
          />
        );
      case 'product_slider_widget':
        return (
          <ProductSliderWidget
            key={`${slice_type}-${index}`}
            data={primary}
            language={language}
          />
        );
      case 'logo_banner':
        return <LogoBanner key={`${slice_type}-${index}`} items={items} />;
      case 'four_items':
        return (
          <FourItemsSlice
            key={`${slice_type}-${index}`}
            data={primary}
            items={items}
          />
        );
      case 'hubspot_form':
        return <HubspotForm key={`${slice_type}-${index}`} data={primary} />;
      case 'title_and_description':
        return (
          <TitleAndDescriptionSlice
            key={`${slice_type}-${index}`}
            data={primary}
          />
        );
      case 'news':
        const { include_press_releases } = primary;
        if (include_press_releases) {
          if (language.includes('de')) {
            return (
              <LatestPressReleasesGerman
                key={`${slice_type}-${index}`}
                data={primary}
              />
            );
          }
          return (
            <LatestPressReleasesEnglish
              key={`${slice_type}-${index}`}
              data={primary}
            />
          );
        } else {
          if (language.includes('de')) {
            return (
              <LatestSeedtraceInNewsGerman
                key={`${slice_type}-${index}`}
                data={primary}
              />
            );
          }
          return (
            <LatestSeedtraceInNewsEnglish
              key={`${slice_type}-${index}`}
              data={primary}
            />
          );
        }
      default:
        return null;
    }
  });

  return slices;
};

export default SliceZone;

SliceZone.propTypes = {
  allSlices: PropTypes.array,
  language: PropTypes.string,
};
