import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { X } from '@styled-icons/bootstrap/X';
import { Helmet } from 'react-helmet';

const HEIGHT = '700px';

const Close = styled(X)`
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 1;
`;

const Container = styled.div`
  height: 100%;
  padding-top: 8px;

  .meetings-iframe-container {
    height: calc(100% - 8px);

    iframe {
      height: 100% !important;
      margin-bottom: 0 !important;
    }
  }
`;

const ScheduleDemoDialog = ({ open, onClose, bookDemoLink }) => {
  const [script, setScript] = useState('');

  useEffect(() => {
    // Only set script after the component is ready
    setScript(
      `https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js`
    );
  }, []);

  const customStyles = {
    content: {
      zIndex: 1001,
      width: '900px',
      background: 'transparent',
      border: 'none',
      margin: '0 auto',
      padding: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      maxWidth: 'calc(100% - 32px)',
      height: HEIGHT,
      maxHeight: 'calc(100% - 16px)',
      overflow: 'none',
      left: 16,
      right: 16,
    },
  };

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      ariaHideApp={false}
      style={customStyles}
      contentLabel="Schedule Demo Modal"
    >
      <Close size={22} onClick={onClose} />
      <Container>
        <div class="meetings-iframe-container" data-src={bookDemoLink} />
      </Container>
      <Helmet
        script={[
          {
            src: script,
          },
        ]}
      />
    </Modal>
  );
};

export default ScheduleDemoDialog;
