import React, { useRef, useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { Col, Row, media } from 'styled-bootstrap-grid';
import { useForm } from 'react-hook-form';
import { graphql, useStaticQuery } from 'gatsby';
import LoadingButton from './LoadingButton';
import { Button } from './Buttons';
import RichText from './RichText';
import { X } from '@styled-icons/bootstrap/X';
import HighlightedHeadline from './HighlightedHeadline';
import feedback from 'images/download.svg';
import { ErrorMessage, Input, Label, FormControl } from './styles';

const Container = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
`;

const HEIGHT = '550px';

const Header = styled.div`
  padding: 16px;
  background: #f4f5fa;

  ${media.md`
    padding: 32px 16px 16px;
    background: #fff;
    height: ${HEIGHT}; 
    padding: 100px 32px;
  `}
`;

const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
  color: #000;
`;

const FormContainer = styled.div`
  padding: 32px 16px;
  height: 300px;

  ${media.md`
    padding: 140px 40px;
    background: #f4f5fa;
    height: ${HEIGHT};
  `}
`;

const Notice = styled.div`
  p {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    margin-bottom: 0;
    cursor: pointer;
  }
`;

const SignupButton = styled(LoadingButton)`
  margin-bottom: 16px;
`;

const AfterSubmitContainer = styled.div`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  text-align: center;

  p {
    font-size: 14px;
    font-weight: 400;
    color: #000;
  }
`;

const Close = styled(X)`
  color: ${({ theme }) => theme.palette.text.primary};
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  z-index: 1;
`;

const Image = styled.img`
  position: absolute;
  bottom: -50px;
  left: 0;
  z-index: 1;
  width: 40%;

  ${media.xs`
    bottom: 0;
    width: 50%;
  `}
`;

const DownloadDialog = ({ open, onClose, handleFormSubmit, pageLang }) => {
  const { register, handleSubmit, errors, formState, setValue, getValues } =
    useForm();
  const [success, setSucces] = useState(false);
  const [error, setError] = useState(false);

  const {
    allPrismicDownloadDialog: { edges },
  } = useStaticQuery(query);

  const data = edges.find((edge) => edge.node.lang == pageLang).node.data;
  const emailRef = useRef();

  const afterOpenModal = () => {
    emailRef.current.focus();
  };

  const customStyles = {
    content: {
      zIndex: 1001,
      width: '900px',
      margin: '0 auto',
      padding: '0',
      background: '#fff',
      top: '50%',
      left: 16,
      right: 16,
      transform: 'translateY(-50%)',
      maxWidth: 'calc(100% - 32px)',
      height: HEIGHT,
    },
  };

  const onSubmit = async (values) => {
    try {
      await handleFormSubmit({
        values,
      });

      setSucces(true);
    } catch (e) {
      console.error(e);
      setError(true);
    }
  };

  const handleAccept = (evt) => {
    if (evt?.target?.tagName === 'A') return;
    setValue('privacyAccepted', !getValues('privacyAccepted'));
  };

  return (
    <Modal
      isOpen={open}
      onAfterOpen={afterOpenModal}
      onRequestClose={onClose}
      ariaHideApp={false}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <Container>
        <Close size={22} onClick={onClose} />
        <Image src={feedback} />
        <Row>
          <Col md={6} sm={12}>
            <Header>
              <HighlightedHeadline
                pre={data.title}
                highlight={data.title_highlight}
              />
              <Subtitle>{data.subtitle.text}</Subtitle>
            </Header>
          </Col>
          <Col md={6} sm={12}>
            <FormContainer>
              <Row>
                <Col col={12}></Col>
              </Row>
              {success && (
                <AfterSubmitContainer>
                  <h4>{data.success_title.text}</h4>
                  <p>{data.success_message.text}</p>
                  <Button onClick={onClose}>Close</Button>
                </AfterSubmitContainer>
              )}
              {error && (
                <AfterSubmitContainer>
                  <p>{data.error_message.text}</p>
                </AfterSubmitContainer>
              )}
              {!success && !error && (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col col={12}>
                      <FormControl>
                        <Label>{data.email_label.text}</Label>
                        <Input
                          ref={(ref) => {
                            emailRef.current = ref;
                            register({
                              required: 'Required',
                              pattern: {
                                value:
                                  /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                message: 'Please enter a valid email',
                              },
                            })(ref);
                          }}
                          error={errors.email}
                          defaultValue={''}
                          type="email"
                          name="email"
                          disabled={formState.isSubmitting}
                          placeholder="jane.doe@example.com"
                        />
                        {errors.email && (
                          <ErrorMessage>{errors.email.message}</ErrorMessage>
                        )}
                      </FormControl>
                    </Col>
                    <Col col={12}>
                      <FormControl type="checkbox">
                        <input
                          name="privacyAccepted"
                          ref={register({
                            required: 'Please agree to our privacy notice',
                          })}
                          type="checkbox"
                          disabled={formState.isSubmitting}
                        />
                        <Notice onClick={handleAccept}>
                          <RichText render={data.privacy_notice.raw} />
                        </Notice>
                        {errors.privacyAccepted && (
                          <ErrorMessage>
                            {errors.privacyAccepted.message}
                          </ErrorMessage>
                        )}
                      </FormControl>
                    </Col>
                    <Col col={12}>
                      <SignupButton
                        loading={formState.isSubmitting}
                        type="submit"
                      >
                        {data.button_label.text}
                      </SignupButton>
                      <Subtitle>{data.submit_subtitle.text}</Subtitle>
                    </Col>
                  </Row>
                </form>
              )}
            </FormContainer>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default DownloadDialog;

const query = graphql`
  query RequestDownload {
    allPrismicDownloadDialog {
      edges {
        node {
          lang
          data {
            button_label {
              text
            }
            submit_subtitle {
              text
            }
            placeholder {
              text
            }
            subtitle {
              text
            }
            title {
              text
            }
            title_highlight {
              text
            }
            email_label {
              text
            }
            success_title {
              text
            }
            success_message {
              text
            }
            error_message {
              text
            }
            privacy_notice {
              text
              html
              raw
            }
            marketing_notice {
              text
              html
            }
          }
        }
      }
    }
  }
`;
