import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { HighlightedHeadline } from 'components/Shared';
import { Row, Container as Grid, Col, media } from 'styled-bootstrap-grid';

const Container = styled.div`
  margin-bottom: 2rem;
  height: 100%;

  #st-page-wrapper {
    max-height: unset;
  }

  // override font family and h3
  .st-verified-payment-height-wrapper {
    font-family: 'Lato', sans-serif;
    filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.11));
    border-radius: 8px !important;
    overflow: hidden;

    h3 {
      text-transform: initial;
      letter-spacing: normal;
      padding-bottom: 0;
    }
  }

  // 680 is the mobile breakpoint in the consumer app, so let's make sure that this stays the same
  @media only screen and (min-width: 680px) {
    .st-verified-payment-height-wrapper {
      height: calc(100vh - 250px);
    }
  }
`;

const ContentGrid = styled(Grid)`
  position: relative;
  z-index: 10;
  ${media.xl`
      padding: 0px 88px;
  `}
`;

const ContentColumn = styled(Col)`
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  text-align: start;
`;

const StyledDescription = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;

  p {
    font-family: Lato;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const VerifiedPaymentWidget = ({
  data: {
    slug,
    headline_pre_highlight: preHighlight,
    headline_highlight: highlight,
    headline_post_highlight: postHighlight,
    description,
  },
  language,
}) => {
  const [script, setScript] = useState('');

  // get local param from language string e.g. en-US => en
  const lang = language.slice(0, 2);

  useEffect(() => {
    // Only set script after the component is ready
    // to make sure `st-verified-payments-widget` is rendered before loading the script
    setScript(
      `https://products.seedtrace.org/api/verifiedPayments.js?slug=${slug.text}&locale=${lang}`
    );
  }, []);

  return (
    // Extra wrapper needed for the script to have a stable container
    <div id="react-helmet-verified-container">
      <Container>
        <ContentGrid>
          <Row alignItems="stretch" justifyContent="start">
            <ContentColumn col={12} alignItems="start" justifyContent="start">
              <HighlightedHeadline
                pre={preHighlight}
                highlight={highlight}
                post={postHighlight}
                highlightNewline={false}
                hasDarkBackground={false}
              />

              <StyledDescription>
                <div dangerouslySetInnerHTML={{ __html: description.html }} />
              </StyledDescription>

              {/* Rendering the widget */}
              <div id="st-verified-payments-widget" />
              <Helmet
                script={[
                  {
                    src: script,
                  },
                ]}
              />
            </ContentColumn>
          </Row>
        </ContentGrid>
      </Container>
    </div>
  );
};

export default VerifiedPaymentWidget;
