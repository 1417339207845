import React, { useState } from 'react';
import { useMessageForm } from 'src/hooks';
import styled from 'styled-components';
import { Row, media, Container as Grid, Col } from 'styled-bootstrap-grid';
import { HighlightedHeadline } from 'components/Shared';
import LoadingButton from 'components/Shared/LoadingButton';
import RichText from 'components/Shared/RichText';
import { useForm } from 'react-hook-form';

const SliceContainer = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  justify-content: center;
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.secondary.dark};
  padding: 40px 0px;
  ${media.md`
      padding: 64px 0px;
  `}

  ${media.xxl`
      padding: 104px 0px;
  `}
`;

const ContentColumn = styled(Col)`
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  margin: 0px 24px;
  padding: 16px 0px;
`;

const ButtonColumn = styled(Col)`
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  margin: 0px 16px;
  padding: 24px 0px 0px 0px;

  ${media.md`
    padding: 32px 0px 0px 0px;
  `}
`;

const SliceText = styled.p`
  padding: 0px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const Input = styled.input`
  height: 50px;
  padding: 8px 14px;
  margin: 16px 0px;
  background: #fff;
  outline: none !important;
  border: 1px solid;
  border-color: transparent;
  font-family: Lato;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  border-radius: 6px;
  transition: font-size ease-in-out 0.15s;

  &:focus {
    border: 1px solid;
    border-color: ${({ theme }) => theme.palette.primary.main};
  }

  ${({ error, theme }) =>
    error &&
    `
    border-color: ${theme.palette.error.main} !important;
  `}
`;

const ValueInput = styled(Input)`
  height: 40px;
`;

const MessageInput = styled.textarea`
  height: 130px;
  padding: 8px 14px;
  margin: 16px 0px;
  background: #fff;
  outline: none !important;
  border: 1px solid;
  border-color: transparent;
  font-family: Lato;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  border-radius: 6px;
  transition: font-size ease-in-out 0.15s;

  &:focus {
    border: 1px solid;
    border-color: ${({ theme }) => theme.palette.primary.main};
  }

  ${({ error, theme }) =>
    error &&
    `
    border-color: ${theme.palette.error.main} !important;
  `}
`;

const ErrorMessage = styled.p`
  position: absolute;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: 0;
  bottom: 0;
  transform: translateY(25%) translateX(10%);
`;

const PrivacyMessage = styled.p`
  position: absolute;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: 0;
  bottom: 0;
  transform: translateY(75%) translateY(5%);
`;

const FormControl = styled.div`
  position: relative;
  margin: 16px 0px 0px 0px;

  ${({ type }) =>
    type === 'checkbox' &&
    `
    display: flex;
    align-items: center;
  `}
`;

const Notice = styled.div`
  text-align: left;
  width: 100%;
  margin-left: 8px;
  p {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 14px;
    margin-bottom: 0px;
  }

  a {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

const StyledLoadingButton = styled(LoadingButton)`
  color: ${({ theme }) => theme.palette.text.primary};
`;

const ContactFormSlice = ({
  data: {
    contact_form: {
      document: {
        data: {
          headline_pre_highlight: pre_highlight,
          headline_highlight: highlight,
          headline_post_highlight: post_highlight,
          text,
          name_placeholder,
          company_placeholder,
          email_placeholder,
          message_placeholder,
          button_text,
          error_message,
          success_message,
          privacy_notice,
        },
      },
    },
  },
}) => {
  const { register, handleSubmit, errors, formState } = useForm();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { handleFormSubmit } = useMessageForm();
  const onSubmit = async (values) => {
    try {
      await handleFormSubmit({
        ...values,
        privacyText: privacy_notice.text,
      });
      setSuccess(true);
    } catch {
      setError(true);
    }
  };

  return (
    <SliceContainer>
      <Grid>
        <Row justifyContent="center">
          <ContentColumn lg={10}>
            <HighlightedHeadline
              pre={pre_highlight}
              highlight={highlight}
              post={post_highlight}
              hasDarkBackground={true}
            />
          </ContentColumn>
        </Row>
        <Row justifyContent="center">
          <ContentColumn lg={10}>
            <SliceText>{text.text}</SliceText>
          </ContentColumn>
        </Row>
        {!success && !error && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row justifyContent="center">
              <Col col={10} xl={2.5} lg={4} md={6}>
                <ValueInput
                  ref={register({ required: 'Required' })}
                  error={errors.name}
                  type="text"
                  name="name"
                  disabled={formState.isSubmitting}
                  placeholder={name_placeholder.text}
                />
                {errors.name && (
                  <ErrorMessage>{errors.name.message}</ErrorMessage>
                )}
              </Col>
              <Col col={10} xl={2.5} lg={4} md={6}>
                <ValueInput
                  ref={register()}
                  error={errors.company}
                  type="text"
                  name="company"
                  disabled={formState.isSubmitting}
                  placeholder={company_placeholder.text}
                />
                {errors.company && (
                  <ErrorMessage>{errors.company.message}</ErrorMessage>
                )}
              </Col>
            </Row>
            <Row justifyContent="center">
              <Col col={10} xl={5} lg={8} md={12}>
                <ValueInput
                  ref={register({
                    required: 'Required',
                    pattern: {
                      value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      message: 'Please enter a valid email',
                    },
                  })}
                  error={errors.email}
                  type="text"
                  name="email"
                  disabled={formState.isSubmitting}
                  placeholder={email_placeholder.text}
                />
                {errors.email && (
                  <ErrorMessage>{errors.email.message}</ErrorMessage>
                )}
              </Col>
            </Row>

            <Row justifyContent="center">
              <Col col={10} xl={5} lg={8} md={12}>
                <MessageInput
                  ref={register({ required: 'Required' })}
                  error={errors.message}
                  type="text"
                  name="message"
                  cols="40"
                  rows="5"
                  disabled={formState.isSubmitting}
                  placeholder={message_placeholder.text}
                />
                {errors.message && (
                  <ErrorMessage>{errors.message.message}</ErrorMessage>
                )}
              </Col>
            </Row>

            <Row justifyContent="center">
              <Col col={10} xl={5} lg={8} md={12}>
                <FormControl type="checkbox">
                  <input
                    name="privacyAccepted"
                    ref={register({
                      required: 'Please agree to our privacy notice',
                    })}
                    type="checkbox"
                    disabled={formState.isSubmitting}
                  />
                  <Notice>
                    <RichText render={privacy_notice.raw} />
                  </Notice>
                  {errors?.privacyAccepted && (
                    <PrivacyMessage>
                      {errors?.privacyAccepted.message}
                    </PrivacyMessage>
                  )}
                </FormControl>
              </Col>
            </Row>

            <Row justifyContent="center">
              <ButtonColumn xl={2} lg={3} md={4}>
                <StyledLoadingButton
                  loading={formState.isSubmitting}
                  type="submit"
                  size="small"
                  className="plausible-event-name=Contact+Form+Submit"
                >
                  {button_text.text}
                </StyledLoadingButton>
              </ButtonColumn>
            </Row>
          </form>
        )}
        {success && (
          <Row justifyContent="center">
            <ContentColumn lg={10}>
              <SliceText className="contact-form-successmessage">
                {success_message.text || ''}
              </SliceText>
            </ContentColumn>
          </Row>
        )}
        {error && (
          <Row justifyContent="center">
            <ContentColumn lg={10}>
              <SliceText>{error_message.text || ''}</SliceText>
            </ContentColumn>
          </Row>
        )}
      </Grid>
    </SliceContainer>
  );
};

export default ContactFormSlice;
