// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-de-de-404-js": () => import("./../../../src/pages/de-de/404.js" /* webpackChunkName: "component---src-pages-de-de-404-js" */),
  "component---src-pages-de-de-index-js": () => import("./../../../src/pages/de-de/index.js" /* webpackChunkName: "component---src-pages-de-de-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog_post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-legal-page-js": () => import("./../../../src/templates/legal_page.js" /* webpackChunkName: "component---src-templates-legal-page-js" */),
  "component---src-templates-press-post-js": () => import("./../../../src/templates/press_post.js" /* webpackChunkName: "component---src-templates-press-post-js" */),
  "component---src-templates-register-form-page-js": () => import("./../../../src/templates/register_form_page.js" /* webpackChunkName: "component---src-templates-register-form-page-js" */),
  "component---src-templates-subpage-js": () => import("./../../../src/templates/subpage.js" /* webpackChunkName: "component---src-templates-subpage-js" */)
}

