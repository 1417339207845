import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import ClickAwayListener from 'react-click-away-listener';
import { media } from 'styled-bootstrap-grid';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scaleY(0.5) translateY(-50%);
  }
  to {
    opacity: 1;
    transform: scaleY(1) translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: scaleY(1) translateY(0);
  }
  to {
    opacity: 0;
    transform: scaleY(0.5) translateY(-50%);
  }
`;

const OptionContainer = styled.div`
  position: absolute;
  margin-top: 5px;
  padding: 4px 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0 0 5px 3px #f4f5fa;
  animation: ${(props) => (props.out ? fadeOut : fadeIn)} 0.2s linear;
  visibility: ${(props) => (props.out ? 'hidden' : 'visible')};
  transition: visibility 0.2s linear;
`;

const DropDownButton = styled.div`
  height: 32px;
  padding: 8px;
  display: flex;
  border: ${({ theme }) => `1px solid ${theme.palette.success.light}`};
  font-family: Lato;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 6px;

  ${({ open, theme }) =>
    open &&
    `
     background-color: ${theme.palette.success.light};
  `}

  ${media.xs`
    padding: 5px;
    font-size: 12px;
  `}
`;

const Item = styled.a`
  padding: 6px 8px 6px 5px;
  margin: 2px 0;
  display: flex;
  font-family: Lato;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1;
  cursor: pointer;
  white-space: nowrap;
  border-left: 4px solid #fff;

  ${({ active, theme }) =>
    active &&
    `
     border-left: 3px solid ${theme.palette.primary.main};
  `}

  ${media.xs`
    padding: 3px 5px 3px 2px;
    font-size: 12px;
  `}
`;

const DropDown = ({ currentValue, options, onChange, renderItems }) => {
  const [listOpen, setListOpen] = useState(false);

  const handleSelectItem = (item) => {
    onChange(item);
    setListOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={() => setListOpen(false)}>
      <div>
        <DropDownButton open={listOpen} onClick={() => setListOpen(!listOpen)}>
          {renderItems ? (
            renderItems(currentValue)
          ) : (
            <div>{currentValue.label}</div>
          )}
        </DropDownButton>
        <OptionContainer out={!listOpen}>
          {options.map((option, index) => (
            <Item
              key={index}
              onClick={() => handleSelectItem(option)}
              active={option.value === currentValue.value}
            >
              {renderItems ? renderItems(option) : <div>{option.label} </div>}
            </Item>
          ))}
        </OptionContainer>
      </div>
    </ClickAwayListener>
  );
};

export default DropDown;
