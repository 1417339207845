import React from 'react';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import { ArrowRightShort } from '@styled-icons/bootstrap/ArrowRightShort';
import { useRequestDemo } from '../../hooks';

const ButtonBase = styled.button`
  background-color: ${({ theme }) => theme.palette.primary.main};
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.text.main};
  border: 1px solid;
  border-color: transparent;
  border-radius: ${({ theme }) => theme.borders.radius};
  outline: none !important;
  cursor: pointer;
  transition: all 0.125s ease-out;
  white-space: nowrap;
  min-width: 104px;
  height: 36px;
  padding: 0px 32px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
`;

const ButtonBaseExternal = styled.a`
  display: inline-block;
  background-color: ${({ theme }) => theme.palette.primary.main};
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  color: white;
  border: 1px solid;
  text-decoration: none !important;
  border-color: transparent;
  border-radius: ${({ theme }) => theme.borders.radius};
  outline: none !important;
  cursor: pointer;
  transition: all 0.125s ease-out;
  white-space: nowrap;
  min-width: 104px;
  height: 36px;
  padding: 0px 16px 2px 16px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
`;

export const Button = ({
  type,
  to,
  onClick,
  children,
  displayArrow,
  ...props
}) => {
  if (type == 'submit') {
    return (
      <ButtonBase type={type} {...props}>
        {children}
      </ButtonBase>
    );
  } else if (onClick) {
    return (
      <ButtonBase onClick={onClick} {...props}>
        {children}
      </ButtonBase>
    );
  } else if (to?.startsWith('http')) {
    return (
      <ButtonBaseExternal
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
      >
        {children}
      </ButtonBaseExternal>
    );
  } else {
    const { handleOpenScheduling } = useRequestDemo();
    return (
      <ButtonBase
        className={to ? '' : 'plausible-event-name=Book+Demo+Button+Click'}
        onClick={() => (to ? navigate(to) : handleOpenScheduling())}
        {...props}
      >
        {children}
      </ButtonBase>
    );
  }
};

export const PrimaryButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.text.primary};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const SecondaryButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.secondary.dark};
  color: ${({ theme }) => theme.palette.text.secondary};

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const OutlinedButton = styled(Button)`
  background-color: transparent;
  border-width: 2px;
  border-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &:active {
    background-color: ${({ theme }) => theme.palette.primary.light};
    color: ${({ theme }) => theme.palette.text.secondary};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  }
`;

export const TextOnlyButton = styled(Button)`
  color: ${({ theme }) => theme.palette.text.primary};
  border-color: transparent;
  background-color: transparent;
`;

const ArrowButton = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  height: 45px;
  padding: 10px 0px;
  text-decoration: none;
  white-space: nowrap;

  .text {
    margin-left: 32px;
    text-decoration: none;
    font-family: Lato;
    font-size: 14px;
    color: ${({ theme, inverted }) =>
      inverted ? '#fff' : theme.palette.text.primary};
    font-weight: 600;
    left: 36px;
    bottom: 14px;
    text-align: start;
  }

  .dot {
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-radius: 15px;
    position: absolute;
    left: 8px;
    bottom: 16px;
    width: 13px;
    height: 13px;
    padding: 0px;
    margin: 0px;
  }

  .arrow {
    color: ${({ theme }) => theme.palette.text.primary};
    position: absolute;
    left: 0px;
    bottom: 9px;
    margin-left: -4px;
    transition: transform 0.25s ease-in-out;
  }

  &:hover .arrow {
    transform: translate3d(40%, 0%, 0);
  }
`;

const ArrowButtonExternal = styled.a`
  position: relative;
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  width: 132px;
  height: 45px;
  padding: 10px 0px;
  cursor: pointer;

  .text {
    font-family: Lato;
    font-size: 14px;
    color: ${({ theme, inverted }) =>
      inverted ? '#fff' : theme.palette.text.primary};
    font-weight: 600;
    width: 100px;
    left: 36px;
    bottom: 18px;
    line-height: 10px;
    text-align: start;
    position: absolute;
  }

  .dot {
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-radius: 15px;
    position: absolute;
    left: 8px;
    bottom: 16px;
    width: 13px;
    height: 13px;
    padding: 0px;
    margin: 0px;
  }

  .arrow {
    color: ${({ theme }) => theme.palette.text.primary};
    position: absolute;
    left: 0px;
    bottom: 9px;
    margin-left: -4px;
    transition: transform 0.25s ease-in-out;
  }

  &:hover .arrow {
    transform: translate3d(40%, 0%, 0);
  }
`;

export const ReadMoreButton = ({ buttonText, onClick, to, ...props }) => {
  if (to?.startsWith('http')) {
    return (
      <ArrowButtonExternal
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
      >
        <span className="text">{buttonText}</span>
        <div className="dot" />
        <ArrowRightShort size={27} className="arrow" />
      </ArrowButtonExternal>
    );
  }

  if (onClick) {
    return (
      <ArrowButtonExternal onClick={onClick} {...props}>
        <span className="text">{buttonText}</span>
        <div className="dot" />
        <ArrowRightShort size={27} className="arrow" />
      </ArrowButtonExternal>
    );
  }

  return (
    <ArrowButton to={to} {...props}>
      <span className="text">{buttonText}</span>
      <div className="dot" />
      <ArrowRightShort size={27} className="arrow" />
    </ArrowButton>
  );
};
