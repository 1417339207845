export const truncate = (input, limit) =>
  input.length > limit ? `${input.substring(0, limit)}...` : input;

const LATEST_CONTENT_VERSION = 'v3.0.0';

export const getRelevantNode = (data, pageLang) => {
  // Language filtering
  let relevantNodes = data.nodes.filter((node) => node.lang == pageLang);
  // Fallback to default language if necessary
  // Note that fore more than two languages this logic needs to be adopted.
  if (data.nodes.length > 0 && relevantNodes.length == 0) {
    relevantNodes = data.nodes;
  }

  // In case you are updating existing content structures, duplicate and tag new content.
  // Pick tagged content or fallback to first node
  let taggedNodes = relevantNodes.filter((node) =>
    node.tags?.includes(LATEST_CONTENT_VERSION)
  );
  return LATEST_CONTENT_VERSION && taggedNodes.length === 1
    ? taggedNodes[0]
    : relevantNodes[0];
};
