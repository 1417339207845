import React from 'react';
import styled from 'styled-components';
import { Col, Container as Grid, Row, media } from 'styled-bootstrap-grid';
import Imgix from 'react-imgix';
import Carousel from 'react-multi-carousel';
import { useTheme } from 'src/hooks';
import { SlideDot as Dot } from 'components/Shared';
import { SliceContainer, SliceCaption } from './NamedSlice';
import { Linkedin } from '@styled-icons/entypo-social/Linkedin';
import { HighlightedHeadline } from '../Shared';

const ContentColumn = styled(Col)`
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  padding: 16px;

  ${media.lg`
      padding: 32px 16px;
  `}
`;

const CarouselColumn = styled(Col)`
  padding-bottom: 48px;
`;

const StyledCaption = styled(SliceCaption)`
  text-align: center;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const Member = styled.div`
  text-align: center;
  margin: 48px 0px;
`;

const MemberPicture = styled(Imgix)`
  object-fit: cover;
  width: 200px;
  height: 200px;
  border-radius: 8px;
`;

const MemberName = styled.h4`
  margin: 16px 0px 4px 0px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const MemberPosition = styled.p`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 600;
  margin: 8px 0px;
`;

const MemberDescription = styled.p`
  color: ${({ theme }) => theme.palette.text.primary};
  margin: 8px 0px 16px 0px;
  word-wrap: normal;
`;

const MemberLinkedIn = styled.a`
  color: ${({ theme }) => theme.palette.text.primary};
`;

const Circle = styled.div`
  border: 1px solid !important;
  padding: 1.5px 6px;
  margin: 16px 0px;
  border-radius: 25px;
`;

const ItemPadding = styled.div`
  padding: 0px 16px 32px 16px;
  ${media.md`
      padding: 0px 48px 48px 48px;
  `}
`;

const TeamRow = styled(Row)`
  column-gap: 50px;
`;

const TeamMember = ({
  item: { person, position, image, description, linkedin },
}) => {
  return (
    <Member>
      <Grid>
        <Row alignItems="center" justifyContent="center">
          <Col col={12}>
            <MemberPicture
              className="lazyload"
              src={image?.url}
              sizes="100vw"
              htmlAttributes={{
                alt: image?.alt,
              }}
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
            />
          </Col>
          <Col col={12}>
            <MemberName>{person.text}</MemberName>
          </Col>
          <Col col={12}>
            <MemberPosition>{position.text}</MemberPosition>
          </Col>
          <Col col={12}>
            <MemberDescription>{description.text}</MemberDescription>
          </Col>
          <Col auto={true}>
            <MemberLinkedIn
              className="icon"
              href={linkedin.url}
              target="_blank"
            >
              <Circle>
                <Linkedin size={14} />
              </Circle>
            </MemberLinkedIn>
          </Col>
        </Row>
      </Grid>
    </Member>
  );
};

const TeamSlice = ({
  data: {
    slice_title: sliceTitle,
    headline_pre_highlight: preHighlight,
    headline_highlight: highlight,
    headline_post_highlight: postHighlight,
  },
  items,
}) => {
  const { breakpoints } = useTheme();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: breakpoints.lg },
      items: 1,
    },
    mobile: {
      breakpoint: { max: breakpoints.lg, min: 0 },
      items: 1,
    },
  };
  return (
    <SliceContainer narrowPadding={true}>
      <Grid>
        {sliceTitle.text && (
          <Row alignItems="stretch" justifyContent="center">
            <ContentColumn col={12} alignItems="center" justifyContent="center">
              <StyledCaption>{sliceTitle.text}</StyledCaption>
            </ContentColumn>
          </Row>
        )}

        {(preHighlight.text || highlight.text || postHighlight.text) && (
          <Row alignItems="stretch" justifyContent="center">
            <ContentColumn col={12} alignItems="center" justifyContent="center">
              <HighlightedHeadline
                pre={preHighlight}
                highlight={highlight}
                post={postHighlight}
                highlightNewline={false}
              />
            </ContentColumn>
          </Row>
        )}

        <TeamRow alignItems="stretch" justifyContent="center">
          {items.map((item, index) => {
            return (
              <Col key={index} col={12} lg={3.5} className="hiddenLgDown">
                <TeamMember item={item} />
              </Col>
            );
          })}
          <CarouselColumn col={12} className="hiddenLgUp">
            <Carousel
              removeArrowOnDeviceType={['tablet', 'mobile']}
              responsive={responsive}
              ssr
              transitionDuration={200}
              showDots={true}
              customDot={<Dot />}
            >
              {items.map((item, index) => (
                <ItemPadding key={index}>
                  <TeamMember key={`team-member-${index}`} item={item} />
                </ItemPadding>
              ))}
            </Carousel>
          </CarouselColumn>
        </TeamRow>
      </Grid>
    </SliceContainer>
  );
};

export default TeamSlice;
