import React from 'react';
import { DateTime } from 'luxon';

const Date = ({ isoDate, pageLang }) => {
  const isGerman = pageLang === 'de-de';
  const date = DateTime.fromISO(isoDate);

  return (
    <span>
      {date
        .setLocale(isGerman ? 'de' : 'en')
        .toLocaleString(DateTime.DATE_FULL)}
    </span>
  );
};

export default Date;
