import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import NewsSlice from './NewsSlice';

const LatestSeedtraceInNewsGerman = ({ data }) => {
  const { allPrismicPressPost: posts = {} } = useStaticQuery(graphql`
    {
      allPrismicPressPost(
        sort: { fields: first_publication_date, order: DESC }
        limit: 10
        filter: { data: { post_type: { eq: true } }, lang: { eq: "de-de" } }
      ) {
        nodes {
          lang
          url
          data {
            title {
              text
            }
            subtitle {
              text
            }
            featured_image {
              url
            }
            company_logo {
              url
            }
            release_date
            post_type # false = Press release, true = External news
            read_more_link {
              url
              target
            }
            video_link {
              url
            }
            press_release_document {
              url
            }
          }
          uid
          first_publication_date
        }
      }
    }
  `);

  return (
    <NewsSlice data={data} recentPosts={posts} desktopSlider type="news" />
  );
};

export default LatestSeedtraceInNewsGerman;
