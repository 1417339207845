import React, { createContext, useState, useEffect } from 'react';
import throttle from 'lodash.throttle';
import axios from 'axios';
import { NewsletterDialog } from 'components/Shared';
import { useLocation } from '@reach/router';

const SignUpNewsletterContext = createContext();

const SignUpNewsletterProvider = ({ children }) => {
  const submitURL = `https://api.hsforms.com/submissions/v3/integration/submit/5223531/b4c19dcc-35f1-4ef1-b498-0c04d132c7a5`;
  const [dialogOpen, setDialogOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const newsletterStatus = sessionStorage.getItem('sawNewsletterDialog');

    const handleScroll = throttle(() => {
      const scrollpercent =
        (document.body.scrollTop + document.documentElement.scrollTop) /
        (document.documentElement.scrollHeight -
          document.documentElement.clientHeight);
      if (scrollpercent > 0.3) {
        setDialogOpen(true);
        sessionStorage.setItem('sawNewsletterDialog', 'true');
      }
    }, 100);

    if (
      newsletterStatus !== 'true' &&
      location.pathname.split('/').includes('blog')
    ) {
      window.addEventListener('scroll', handleScroll, { passive: true });
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [dialogOpen]);

  const handleCloseDialog = () => setDialogOpen(false);

  const onSubmit = async (values) => {
    const result = await axios.post(submitURL, {
      ...valuesToHubspotBody(values),
    });

    return result;
  };

  return (
    <SignUpNewsletterContext.Provider
      value={{
        openNewsletterDialog: () => setDialogOpen(true),
      }}
    >
      <NewsletterDialog
        key={`newsletter-dialog-${dialogOpen}`}
        open={dialogOpen}
        onClose={handleCloseDialog}
        handleFormSubmit={onSubmit}
      />
      {children}
    </SignUpNewsletterContext.Provider>
  );
};

const valuesToHubspotBody = ({ values, privacyText, marketingText }) => {
  return {
    fields: [
      {
        name: 'email',
        value: values.email,
      },
    ],
    context: {
      pageUri: window.location.pathname,
      pageName: 'seedtrace website',
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: values.privacyAccepted,
        text: privacyText,
        communications: [
          {
            value: values.marketingAccepted,
            subscriptionTypeId: 999,
            text: marketingText,
          },
        ],
      },
    },
  };
};

export { SignUpNewsletterProvider };

export default SignUpNewsletterContext;
