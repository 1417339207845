import React from 'react';
import Imgix from 'react-imgix';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

const Image = styled(Imgix)`
  width: 100%;
  object-fit: cover;
  margin: 16px 0;
`;

const ImageSlice = ({ data: { image } }) => {
  return (
    <Image
      className="lazyload"
      htmlAttributes={{
        alt: image?.alt,
      }}
      src={image?.url}
      sizes="100vw"
      attributeConfig={{
        src: 'data-src',
        srcSet: 'data-srcset',
        sizes: 'data-sizes',
      }}
    />
  );
};

export default ImageSlice;
