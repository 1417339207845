import React from 'react';
import styled from 'styled-components';
import Imgix from 'react-imgix';
import { Row, Col, Container as Grid, media } from 'styled-bootstrap-grid';

const Image = styled(Imgix)`
  width: 100%;
  height: 240px;
  object-fit: cover;
  box-shadow: ${({ theme }) => theme.shadows.card};

  ${media.lg`
    height: 400px;
  `}
`;

const Container = styled(Row)`
  margin-bottom: 32px;
`;

const TwoImageSlice = ({ data: { image_one, image_two } }) => {
  return (
    <Container>
      <Col col={12} lg={6}>
        <Image
          className="lazyload"
          htmlAttributes={{
            alt: image_one?.alt,
          }}
          src={image_one?.url}
          sizes="100vw"
          attributeConfig={{
            src: 'data-src',
            srcSet: 'data-srcset',
            sizes: 'data-sizes',
          }}
        />
      </Col>
      <Col col={12} lg={6}>
        <Image
          className="lazyload"
          htmlAttributes={{
            alt: image_two?.alt,
          }}
          src={image_two?.url}
          sizes="100vw"
          attributeConfig={{
            src: 'data-src',
            srcSet: 'data-srcset',
            sizes: 'data-sizes',
          }}
        />
      </Col>
    </Container>
  );
};

export default TwoImageSlice;
