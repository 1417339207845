exports.linkResolver = (doc) => {
  if (doc.link_type === 'Document') {
    let url = '/';

    if (doc.lang === 'de-de') {
      url += doc.lang + '/';
    }

    if (doc.uid && !doc.uid.includes('home')) {
      url += doc.uid;
    }
    return url;
  }

  if (doc.type === 'press_post') {
    let url = '/';

    if (doc.lang === 'de-de') {
      url += doc.lang + '/';
    }

    url += 'press/';

    if (doc.uid) {
      url += doc.uid;
    }
    return url;
  }

  if (doc.type === 'blog_post') {
    let url = '/';

    if (doc.lang === 'de-de') {
      url += doc.lang + '/';
    }

    url += 'blog/';

    if (doc.uid) {
      url += doc.uid;
    }
    return url;
  }
  // Homepage route fallback
  return '/';
};
