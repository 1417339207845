import React, { createContext } from 'react';
import axios from 'axios';

const MessageFormContext = createContext();

const MessageFormProvider = ({ children }) => {
  const submitURL = `https://api.hsforms.com/submissions/v3/integration/submit/5223531/e0d6803d-3511-4268-b28c-607af491647c`;
  const handleFormSubmit = async (values) => {
    const resp = await axios.post(submitURL, {
      ...valuesToHubspotBody(values),
    });

    return resp;
  };

  return (
    <MessageFormContext.Provider value={{ handleFormSubmit }}>
      {children}
    </MessageFormContext.Provider>
  );
};

const valuesToHubspotBody = (values) => {
  return {
    fields: [
      {
        name: 'full_name',
        value: values.name,
      },
      {
        name: 'company',
        value: values.company,
      },
      {
        name: 'email',
        value: values.email,
      },
      {
        name: 'message',
        value: values.message,
      },
    ],
    context: {
      pageUri: window.location.pathname,
      pageName: 'seedtrace website',
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: values.privacyAccepted,
        text: values.privacyText,
      },
    },
  };
};

export { MessageFormProvider };

export default MessageFormContext;
