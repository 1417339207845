import React from 'react';
import PropTypes from 'prop-types';
import { Button } from './Buttons';
import Loader from './Loader';
import styled from 'styled-components';
import { Check } from '@styled-icons/bootstrap/Check';

const StyledButton = styled(Button)`
  position: relative;
  overflow: hidden;

  background: ${({ success, theme }) => success && theme.palette.grey[300]};
`;

const ProgressContainer = styled(({ visible, ...props }) => <div {...props} />)(
  ({ visible, theme }) => {
    let styles = {
      position: 'absolute',
      marginLeft: 'auto',
      marginRight: 'auto',
      left: '50%',
      right: '0',
      opacity: 0,
      transform: 'translate3d(-60%, 30px, 0)',
      transition: 'transform 0.125s ease-in',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& > *': {
        color: theme.palette.text.primary,
        borderTopColor: theme.palette.text.primary,
        borderRightColor: theme.palette.text.primary,
        borderBottomColor: theme.palette.text.primary,
        width: '16px',
        height: '16px',
      },
    };

    if (visible) {
      styles = {
        ...styles,
        opacity: 1,
        transform: 'translate3d(-60%, 2px, 0)',
      };
    }

    return styles;
  }
);

const Label = styled.span`
  ${({ theme, visible }) => {
    let styles = {
      transition: 'all 0.125s ease-in',
      opacity: 1,
      transform: 'translateY(0)',
    };

    if (!visible) {
      styles = {
        ...styles,
        opacity: 0,
        transform: 'translateY(-30px)',
      };
    }

    return styles;
  }}
`;

const StyledCheck = styled(Check)`
  margin: 0 auto;
  color: ${({ theme }) => theme.palette.grey[600]};
  width: 22px !important;
  height: 22px !important;
  margin-top: -3px;
`;

const LoadingButton = ({
  children,
  loading,
  disabled,
  success,
  onClick,
  type,
  ...rest
}) => {
  return (
    <StyledButton
      success={success}
      type={type}
      onClick={onClick}
      disabled={loading || disabled}
      {...rest}
    >
      {success && (
        <ProgressContainer visible>
          <StyledCheck size={24} />
        </ProgressContainer>
      )}
      <ProgressContainer visible={loading && !success}>
        <Loader />
      </ProgressContainer>
      <Label visible={!loading && !success}>{children}</Label>
    </StyledButton>
  );
};

LoadingButton.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default LoadingButton;
