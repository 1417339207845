import React from 'react';
import Dots from 'src/images/dots_horizontal.svg';
import styled from 'styled-components';
import { Row, media, Container as Grid, Col } from 'styled-bootstrap-grid';
import {
  PrimaryButton,
  OutlinedButton,
  HighlightedHeadline,
} from 'components/Shared';
import { ExpertImage } from 'components/Graphics';
import {
  useRequestDemo,
  useNewsletterDialog,
  useRequestDownload,
} from 'src/hooks';

const SliceContainer = styled.div`
  position: relative;
  z-index: 10;
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.secondary.dark};
`;

const StyledGrid = styled(Grid)`
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  padding: 40px 16px;

  ${media.md`
      padding: 64px 0px;
  `}

  ${media.xxl`
      padding: 104px 0px;
  `}
`;

const StyledDots = styled.img`
  display: none;
  z-index: 2;

  ${media.md`
    display: block;
    position: absolute;
    bottom: 88px;
    left: 0px;
  `}
`;

const ContentColumn = styled(Col)`
  height: 100%;
  margin-bottom: 16px;

  ${({ center }) =>
    center &&
    `
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    margin: 0px 32px;
    padding: 16px 0px;
  `}
`;

const ButtonColumn = styled.div`
  ${({ center }) =>
    center &&
    `
height: 100%;
justify-content: center;
align-items: flex-start;
text-align: center;
margin: 0px 16px;
padding: 16px 0px 16px 0px;
`}
`;

const SliceText = styled.div`
  margin: 32px 0;
  color: ${({ theme }) => theme.palette.text.secondary};

  p {
    padding: 0px;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

const ButtonDisclaimer = styled.h4`
  padding-top: 32px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const StyledAvatar = styled(ExpertImage)`
  width: 280px;
  height: 280px;
`;

const Name = styled.h4`
  margin: 16px 0;
  font-size: 28px;
  font-weight: 900;
  color: #fff;
  text-transform: none;
`;

const Title = styled.h5`
  font-size: 18px;
  font-weight: 700;
  color: #fff;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  ${({ center }) => center && `justify-content: center;`}
`;

const DarkDividerSlice = ({
  data: {
    button_text,
    button_text_demo,
    button_text_secondary,
    button_text_newsletter,
    headline_pre_highlight: pre_highlight,
    headline_highlight: highlight,
    headline_post_highlight: post_highlight,
    book_demo_link: bookDemoLink,
    navigation_link,
    navigation_link_secondary,
    navigation_link_is_download,
    text,
    post_button_text,
    image_avatar,
    avatar_name,
    avatar_title,
  },
}) => {
  const { openDemoDialog, handleOpenScheduling } = useRequestDemo();
  const { openNewsletterDialog } = useNewsletterDialog();
  const { handleOpenDownload } = useRequestDownload();
  const hasImage = !!image_avatar?.url;

  return (
    <SliceContainer>
      <StyledGrid>
        <Row justifyContent={!hasImage && 'center'}>
          <ContentColumn md={hasImage ? 6 : 9} mdOffset={1} center={!hasImage}>
            <HighlightedHeadline
              pre={pre_highlight}
              highlight={highlight}
              post={post_highlight}
              hasDarkBackground
              highlightNewline
            />
            {text?.html && (
              <SliceText dangerouslySetInnerHTML={{ __html: text.html }} />
            )}
            <ButtonContainer center={!hasImage}>
              {button_text.text &&
                !button_text_demo?.text &&
                (navigation_link_is_download ? (
                  <PrimaryButton
                    onClick={() => handleOpenDownload(navigation_link?.url)}
                    size="small"
                  >
                    {button_text.text}
                  </PrimaryButton>
                ) : !!bookDemoLink?.url ? (
                  <PrimaryButton
                    onClick={() => handleOpenScheduling(bookDemoLink?.url)}
                    size="small"
                  >
                    {button_text.text}
                  </PrimaryButton>
                ) : (
                  <PrimaryButton to={navigation_link?.url} size="small">
                    {button_text.text}
                  </PrimaryButton>
                ))}

              {button_text_demo?.text && (
                <PrimaryButton
                  onClick={() => openDemoDialog()}
                  size="small"
                  className="plausible-event-name=Request+Demo+Button+Click"
                >
                  {button_text_demo.text}
                </PrimaryButton>
              )}

              {button_text_secondary?.text && !button_text_newsletter?.text && (
                <ButtonColumn auto center={!hasImage}>
                  <OutlinedButton
                    to={navigation_link_secondary?.url}
                    size="small"
                  >
                    {button_text_secondary.text}
                  </OutlinedButton>
                </ButtonColumn>
              )}

              {button_text_newsletter?.text && (
                <OutlinedButton
                  onClick={() => openNewsletterDialog()}
                  size="small"
                  className="plausible-event-name=Newsletter+Button+Click"
                >
                  {button_text_newsletter.text}
                </OutlinedButton>
              )}
            </ButtonContainer>

            {post_button_text?.text && (
              <ButtonDisclaimer>{post_button_text?.text}</ButtonDisclaimer>
            )}
          </ContentColumn>
          {hasImage && (
            <ContentColumn md={3} mdOffset={1}>
              <StyledAvatar imageData={image_avatar} />
              <Name>{avatar_name?.text}</Name>
              <Title>{avatar_title?.text}</Title>
            </ContentColumn>
          )}
        </Row>
      </StyledGrid>
      <StyledDots src={Dots} />
    </SliceContainer>
  );
};

export default DarkDividerSlice;
