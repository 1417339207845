import React, { useRef } from 'react';
import TextSlice from './TextSlice';
import TextImageSlice from './TextImageSlice';
import TwoImageSlice from './TwoImageSlice';
import ImageSlice from './ImageSlice';
import VideoSlice from './VideoSlice';
import DownloadSlice from './DownloadSlice';
import styled from 'styled-components';
import { Row, Col, Container as Grid } from 'styled-bootstrap-grid';
import ShareButtons from './ShareButtons';
import { media } from 'styled-bootstrap-grid';

const Container = styled.section`
  position: relative;
  padding: 68px 0;

  h2 {
    font-size: 22px;

    ${media.lg`
      font-size: 32px;
    `}
  }

  p,
  li {
    font-size: 16px;
    line-height: 1.8;
    font-weight: 400;
  }
`;

const StyledShare = styled(ShareButtons)``;

const StyledCol = styled(Col)`
  position: relative;
`;

const BlogSliceZone = ({ allSlices }) => {
  const containerRef = useRef();
  const slices = allSlices.map(({ id, primary, slice_type }, index) => {
    switch (slice_type) {
      case 'image':
        return <ImageSlice key={id} data={primary} />;
      case 'image_text':
        return <TextImageSlice key={id} data={primary} />;
      case 'text':
        return <TextSlice key={id} data={primary} isFirst={index === 0} />;
      case 'two_images':
        return <TwoImageSlice key={id} data={primary} />;
      case 'video':
        return <VideoSlice key={id} data={primary} />;
      case 'dark_divider':
        return <DownloadSlice key={id} data={primary} />;
      default:
        return null;
    }
  });

  return (
    <Container ref={containerRef}>
      <Grid>
        <Row>
          <StyledCol col={12} lg={8} lgOffset={2}>
            <StyledShare bodyRef={containerRef} />
            {slices}
          </StyledCol>
        </Row>
      </Grid>
    </Container>
  );
};

export default BlogSliceZone;
