import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  ${({ isFirst, theme }) =>
    isFirst &&
    `
    p:first-of-type::first-letter {
      font-size: 74px;
      font-weight: 700;
      float: left;
      margin: 0 16px 4px 0;
      line-height: 1;
    }
  `}
`;

const TextSlice = ({ data: { text }, isFirst }) => {
  return (
    <Container
      isFirst={isFirst}
      dangerouslySetInnerHTML={{ __html: text.html }}
    />
  );
};

export default TextSlice;
