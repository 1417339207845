import React, { Fragment } from 'react';
import { Container as Grid, Row, Col, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
import { PostListItem } from 'components/Blog';
import { SlideDot as Dot } from 'components/Shared';
import { SliceContainer, SliceCaption } from './NamedSlice';
import { useTheme } from 'hooks';
import { useQueryParam, StringParam } from 'use-query-params';
import { VideoDialog } from 'components/Shared';

const NewsRow = styled(Row)`
  overflow: visible;
  padding: 16px 0px 0px 0px;
  ${media.md`
      padding: 48px 0px 0px 0px;
  `}
  ${media.xxl`
      padding: 64px 0px 0px 0px;
  `}
`;

const ItemPadding = styled.div`
  padding: 0px 16px 32px 16px;
  ${media.md`
      padding: 0px 48px 48px 48px;
  `}
`;

const NewsSlice = ({ data, recentPosts, desktopSlider, type }) => {
  const [video, setVideo] = useQueryParam(`video-${type}`, StringParam);
  const { breakpoints } = useTheme();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: breakpoints.lg },
      items: 1,
    },
    mobile: {
      breakpoint: { max: breakpoints.lg, min: 0 },
      items: 1,
    },
  };

  if (!recentPosts) return null;

  return (
    <Fragment>
      <VideoDialog
        open={!!video}
        onClose={() => setVideo(null)}
        url={video}
        key="video-modal"
      />

      <SliceContainer>
        <Grid>
          <Row justifyContent="center">
            <SliceCaption>{data.slice_title?.text}</SliceCaption>
          </Row>
          <NewsRow justifyContent="around">
            {!desktopSlider &&
              recentPosts.nodes.map((post, index) => {
                return (
                  <Col key={index} col={3.8} hiddenLgDown={true}>
                    <PostListItem post={post} setVideo={setVideo} />
                  </Col>
                );
              })}
          </NewsRow>
        </Grid>

        <div className={desktopSlider ? 'desktop-slider' : 'hiddenLgUp'}>
          <Carousel
            removeArrowOnDeviceType={['tablet', 'mobile']}
            responsive={responsive}
            ssr
            transitionDuration={200}
            showDots={true}
            customDot={<Dot />}
            renderButtonGroupOutside={true}
          >
            {recentPosts.nodes.map((post, index) => {
              return (
                <ItemPadding key={index}>
                  <PostListItem post={post} key={index} setVideo={setVideo} />
                </ItemPadding>
              );
            })}
          </Carousel>
        </div>
      </SliceContainer>
    </Fragment>
  );
};

export default NewsSlice;
