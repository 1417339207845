import React from 'react';
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';
import { Row, Col, Container as Grid } from 'styled-bootstrap-grid';
import { ArrowRightShort } from '@styled-icons/bootstrap/ArrowRightShort';
import { ArrowLeftShort } from '@styled-icons/bootstrap/ArrowLeftShort';
import PostListItem from './PostListItem';
import { SliceContainer } from '../Slices/NamedSlice';

const Pagination = styled.div`
  display: flex;
  justify-content: center;

  .pagination {
    margin: 32px auto;
    display: flex;

    li {
      font-family: Lato;
      font-weight: 600;
      font-size: 16px;
      padding: 0 12px;
      color: ${({ theme }) => theme.palette.text.primary};
      list-style: none;
      cursor: pointer;

      &.disabled {
        color: ${({ theme }) => theme.palette.grey[600]};
      }

      &.selected {
        font-weight: 600;
      }
    }
  }
`;

const ItemCol = styled(Col)`
  padding: 32px;
`;

const PostList = ({ currentPage, pageCount, onPageChange, posts }) => {
  return (
    <SliceContainer>
      <Grid>
        <Row>
          <Col col={12}>
            <Row justifyContent="between">
              {posts.map(({ node: post }, index) => (
                <ItemCol col={12} lg={4} md={6} key={post.uid}>
                  <PostListItem post={post} />
                </ItemCol>
              ))}
            </Row>
          </Col>
          <Col col={12}>
            <Pagination>
              <ReactPaginate
                initialPage={currentPage}
                pageCount={pageCount}
                pageRangeDisplayed={1}
                marginPagesDisplayed={1}
                onPageChange={onPageChange}
                disableInitialCallback={true}
                nextLabel={<ArrowRightShort size={26} />}
                previousLabel={<ArrowLeftShort size={26} />}
                containerClassName="pagination"
              />
            </Pagination>
          </Col>
        </Row>
      </Grid>
    </SliceContainer>
  );
};

export default PostList;
