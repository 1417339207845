import React from 'react';
import { Container as Grid, Row } from 'styled-bootstrap-grid';
import { SliceContainer, ContentColumn } from './NamedSlice';
import { HighlightedHeadline } from 'components/Shared';
import styled from 'styled-components';

const SliceText = styled.p`
  margin-top: 32px;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const SliceContent = styled.div`
  margin-top: 32px;
  color: ${({ theme }) => theme.palette.secondary.main};

  p {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const TitleAndDescriptionSlice = ({
  data: { title, description, has_small_padding: hasSmallPadding },
}) => {
  return (
    <SliceContainer narrowPadding={hasSmallPadding}>
      <Grid>
        <Row justifyContent="center">
          <ContentColumn col={12} lg={10} centerText>
            <HighlightedHeadline pre={title} />
            {!!description?.html ? (
              <SliceContent
                dangerouslySetInnerHTML={{ __html: description.html }}
              />
            ) : (
              <SliceText>{description.text}</SliceText>
            )}
          </ContentColumn>
        </Row>
      </Grid>
    </SliceContainer>
  );
};

export default TitleAndDescriptionSlice;
