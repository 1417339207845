import React from 'react';
import Imgix from 'react-imgix';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

const StyledImage = styled(Imgix)`
  position: relative;
  object-fit: cover;
  border-radius: 8px;
  width: 466px;
  height: 466px;
  padding: 0px;
  margin: 0px;
`;

const ExpertImage = ({ imageData, className }) => {
  const url = imageData?.url;
  // Image is not working, do we need another fallback??
  // '"https://images.prismic.io/seedtrace-website/21af3d6a-27e7-4ed0-b8a2-c46910e7695c';
  if (!url) return null;

  return (
    <StyledImage
      className={`lazyload ${className}`}
      src={url}
      htmlAttributes={{
        alt: imageData?.alt,
      }}
      sizes="100vw"
      attributeConfig={{
        src: 'data-src',
        srcSet: 'data-srcset',
        sizes: 'data-sizes',
      }}
    />
  );
};

export default ExpertImage;
