import React from 'react';
import styled from 'styled-components';
import { Row, Container as Grid, Col, media } from 'styled-bootstrap-grid';
import { PrimaryButton, HighlightedHeadline } from 'components/Shared';
import { SliceContainer } from './NamedSlice';

const DarkContainer = styled(SliceContainer)`
  background-color: ${({ theme }) => theme.palette.secondary.dark};
  padding: 40px 0px;
  ${media.md`
      padding: 88px 0px;
  `}
  ${media.xxl`
      padding: 104px 0px;
  `}
`;

const SliceCaption = styled.h3`
  margin: 0px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const SliceText = styled.div`
  p {
    font-family: Lato;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    padding: 24px 0px;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

const ContentColumn = styled(Col)`
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  text-align: start;
`;

const JobsGrid = styled(Grid)`
  padding: 16px 0px;
  margin: 0px;
  ${media.lg`
    padding: 16px 0px 16px 48px;
  `}
`;

const JobLink = styled.a`
  text-decoration: none;
`;

const JobContainer = styled.div`
  position: relative;
  border-radius: 8px;
  padding: 16px 24px;
  margin: 16px 0px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const JobContent = styled.div`
  position: relative;
  width: 100%;
  padding: 16px 48px 0px 0px;
`;

const JobTitle = styled.h4`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const JobDescription = styled.p`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const StyledDot = styled.div`
  display: block;
  position: absolute;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 10px;
  width: 10px;
  height: 10px;
  right: 24px;
  top: ${({ top }) => top}%;
`;

const Job = ({
  item: { navigation_link: navigationLink, title1: title, text },
}) => {
  return (
    <JobLink href={navigationLink.url}>
      <JobContainer>
        <JobContent>
          <JobTitle>{title.text}</JobTitle>
          <JobDescription>{text.text}</JobDescription>
        </JobContent>
        <StyledDot top={35} />
        <StyledDot top={50} />
        <StyledDot top={65} />
      </JobContainer>
    </JobLink>
  );
};

const JobsSlice = ({
  data: {
    slice_title: sliceTitle,
    headline_pre_highlight: preHighlight,
    headline_highlight: highlight,
    headline_post_highlight: postHighlight,
    text,
    button_text: buttonText,
    navigation_link: navigationLink,
  },
  items,
}) => {
  return (
    <DarkContainer>
      <Grid>
        <Row alignItems="stretch" justifyContent="start">
          <ContentColumn
            col={12}
            lg={6}
            alignItems="start"
            justifyContent="start"
          >
            <SliceCaption>{sliceTitle.text}</SliceCaption>
            <HighlightedHeadline
              pre={preHighlight}
              highlight={highlight}
              post={postHighlight}
              highlightNewline={true}
              hasDarkBackground={true}
            />
            <SliceText dangerouslySetInnerHTML={{ __html: text.html }} />
            {buttonText.text && (
              <PrimaryButton to={navigationLink?.url} size="small">
                {buttonText.text}
              </PrimaryButton>
            )}
          </ContentColumn>
          <ContentColumn
            col={12}
            lg={6}
            alignItems="stretch"
            justifyContent="center"
          >
            <JobsGrid>
              <Row>
                {items.map((item, index) => {
                  return (
                    <Col col={12} key={index}>
                      <Job item={item} />
                    </Col>
                  );
                })}
              </Row>
            </JobsGrid>
          </ContentColumn>
        </Row>
      </Grid>
    </DarkContainer>
  );
};

export default JobsSlice;
