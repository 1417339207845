import React from 'react';
import styled from 'styled-components';
import DotsHorizontal from 'src/images/dots_horizontal.svg';

const Dots = styled.img`
  display: block;
  position: relative;
`;

const DotsTopLeft = styled(Dots)`
  top: 0%;
  transform: translateY(-50%) translateX(-18%);
  left: 0%;
`;

const DotsLowerHalfLeft = styled(Dots)`
  top: 100%;
  transform: translateY(-50%) translateX(-50%);
  left: 25%;
`;

const DotsCenteredRight = styled(Dots)`
  display: block;
  position: relative;
  top: 80%;
  transform: translateY(-70%) translateX(-80%);
  left: 100%;
`;

const DotsCenteredHalfRight = styled(Dots)`
  display: block;
  position: relative;
  top: 60%;
  transform: translateY(-70%) translateX(-80%);
  left: 90%;
`;

const BackgroundDots = ({ configuration }) => {
  switch (configuration) {
    case 'landing_demonstrate_impact':
      return <DotsTopLeft src={DotsHorizontal} />;
    case 'product_feature_demonstrate_impact':
      return <DotsCenteredRight src={DotsHorizontal} />;
    case 'landing_integrations':
      return <DotsLowerHalfLeft src={DotsHorizontal} />;
    case 'product_feature_trace_origin':
      return <DotsCenteredRight src={DotsHorizontal} />;
    case 'press_headline':
      return <DotsCenteredHalfRight src={DotsHorizontal} />;
    default:
      return <></>;
  }
};

export default BackgroundDots;
