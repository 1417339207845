import React from 'react';
import Imgix from 'react-imgix';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

const StyledImage = styled(Imgix)`
  position: relative;
  object-fit: contain;
  right: 0%;
  max-width: 100vw;
  transform: translateX(15%);
  height: 40vh;

  ${media.sm`
    transform: translateX(15%);
    height: 70vh;
  `}

  ${media.md`
    transform: translateX(15%);
    height: 70vh;
  `}
  
  ${media.lg`
    height: 70vh;
    transform: translateX(15%);
  `}

  ${media.xxl`
    height: 90vh;
    transform: translateX(25%);
  `}
`;

const RightBoarderImage = ({ imageData }) => {
  return (
    <StyledImage
      className="lazyload"
      src={imageData.url}
      alt={imageData.alt}
      htmlAttributes={{
        alt: mobileImage.alt,
      }}
      attributeConfig={{
        src: 'data-src',
        srcSet: 'data-srcset',
        sizes: 'data-sizes',
      }}
    />
  );
};

export default RightBoarderImage;
