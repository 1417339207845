import React from 'react';
import { Col, Container as Grid, Row } from 'styled-bootstrap-grid';
import {
  SliceContainer,
  ContentColumn,
  SliceText,
  SliceCaption,
} from './NamedSlice';
import {
  FactCard,
  HighlightedHeadline,
  SecondaryButton,
} from 'components/Shared';

const WhySeedtraceSlice = ({
  // ToDo Spacing
  data: {
    headline_pre_highlight: pre_highlight,
    headline_highlight: highlight,
    headline_post_highlight: post_highlight,
    slice_title,
    text,
    center_text,
    button_text,
    button_link,
  },
  items,
}) => {
  return (
    <SliceContainer>
      <Grid>
        <Row justifyContent="center">
          <ContentColumn col={12} md={9.5} centerText={center_text}>
            <SliceCaption>{slice_title?.text}</SliceCaption>
            <HighlightedHeadline
              pre={pre_highlight}
              highlight={highlight}
              post={post_highlight}
            />
            <SliceText>{text?.text}</SliceText>
          </ContentColumn>
        </Row>
        <Row justifyContent="center">
          {items.map((item, index) => {
            return (
              <Col col={10} md={6} lg={3.8} key={index}>
                {item?.fact?.document.data && (
                  <FactCard fact_data={item?.fact?.document.data} />
                )}
              </Col>
            );
          })}
        </Row>
        {button_text?.text && button_link?.url && (
          <Row justifyContent="center">
            <SecondaryButton to={button_link.url}>
              {button_text?.text}
            </SecondaryButton>
          </Row>
        )}
      </Grid>
    </SliceContainer>
  );
};

export default WhySeedtraceSlice;
