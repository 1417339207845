import React from 'react';
import styled from 'styled-components';
import { Row, Container as Grid, Col, media } from 'styled-bootstrap-grid';
import { HighlightedHeadline, Accordion } from 'components/Shared';
import { SliceContainer, SliceCaption, ContentGrid } from './NamedSlice';

const ContentColumn = styled(Col)`
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  padding: 16px;
`;

const AccordionColumn = styled(Col)`
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  padding: 16px 16px 32px 16px;
`;

const StyledCaption = styled(SliceCaption)`
  text-align: center;
`;

const StyledText = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  margin: 0px;
  padding-bottom: 16px;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const AccordionSlice = ({
  data: {
    slice_title: sliceTitle,
    headline_pre_highlight: preHighlight,
    headline_highlight: highlight,
    headline_post_highlight: postHighlight,
    highlight_newline: highlightNewline,
    text,
  },
  items,
}) => {
  return (
    <SliceContainer>
      <ContentGrid>
        {sliceTitle.text && (
          <Row alignItems="stretch" justifyContent="center">
            <ContentColumn
              col={12}
              lg={11}
              alignItems="center"
              justifyContent="center"
            >
              <StyledCaption>{sliceTitle.text}</StyledCaption>
            </ContentColumn>
          </Row>
        )}

        {(preHighlight.text || highlight.text || postHighlight.text) && (
          <Row alignItems="stretch" justifyContent="center">
            <ContentColumn
              col={12}
              lg={11}
              alignItems="center"
              justifyContent="center"
            >
              <HighlightedHeadline
                pre={preHighlight}
                highlight={highlight}
                post={postHighlight}
                highlightNewline={highlightNewline}
              />
            </ContentColumn>
          </Row>
        )}

        {text.text && (
          <Row alignItems="stretch" justifyContent="center">
            <ContentColumn
              col={12}
              lg={11}
              alignItems="center"
              justifyContent="center"
            >
              <StyledText>{text.text}</StyledText>
            </ContentColumn>
          </Row>
        )}

        <Row alignItems="stretch" justifyContent="center">
          <AccordionColumn col={12} lg={12}>
            <Accordion items={items} />
          </AccordionColumn>
        </Row>
      </ContentGrid>
    </SliceContainer>
  );
};

export default AccordionSlice;
