import { SocialIcons } from 'components/Shared';
import React from 'react';
import { Container as Grid, Row } from 'styled-bootstrap-grid';
import { ContentColumn, SliceCaption, SliceContainer } from './NamedSlice';

const SocialMediaHighlight = ({ data, items }) => {
  const { title1 } = data;

  return (
    <SliceContainer>
      <Grid>
        <Row justifyContent="center">
          <ContentColumn col={12} md={9.5} centerText={true}>
            <SliceCaption>
              {title1?.text || 'seedtrace On social media'}
            </SliceCaption>
          </ContentColumn>
        </Row>
        <Row justifyContent="center">
          <SocialIcons socialLinks={items || []} size={40} />
        </Row>
      </Grid>
    </SliceContainer>
  );
};
export default SocialMediaHighlight;
