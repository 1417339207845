import React, { createContext, useState, useEffect } from 'react';
import { DownloadDialog } from 'components/Shared';
import axios from 'axios';

const RequestDownloadContext = createContext();
const submitURL = `https://api.hsforms.com/submissions/v3/integration/submit/5223531/00ad0d79-32e1-4e1c-a419-adedb80e51b6
`;

const RequestDownloadProvider = ({ pageLang = 'en-us', children }) => {
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');

  const handleOpenDownload = (linkToDocument) => {
    setDownloadLink(linkToDocument);
    setDownloadDialogOpen(true);
  };

  const handleSubmit = async (values) => {
    window.open(downloadLink, '_blank');
    const result = await axios.post(submitURL, {
      ...valuesToHubspotBody({ ...values, lang: pageLang }),
    });
    plausible('Download', { props: { link: downloadLink } });
    return result;
  };

  return (
    <RequestDownloadContext.Provider
      value={{
        handleOpenDownload,
      }}
    >
      <DownloadDialog
        key={`download-dialog-${downloadDialogOpen}`}
        open={downloadDialogOpen}
        onClose={() => setDownloadDialogOpen(false)}
        handleFormSubmit={handleSubmit}
        pageLang={pageLang}
      />
      {children}
    </RequestDownloadContext.Provider>
  );
};

const valuesToHubspotBody = ({
  values,
  privacyText = 'Accepted',
  lang = '',
}) => {
  return {
    fields: [
      {
        name: 'email',
        value: values.email,
      },
      {
        name: 'language',
        value: lang.substring(0, 2),
      },
    ],
    context: {
      pageUri: window.location.pathname,
      pageName: 'seedtrace website',
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: values.privacyAccepted,
        text: privacyText,
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text: 'Marketing accepted',
          },
        ],
      },
    },
  };
};

export { RequestDownloadProvider };

export default RequestDownloadContext;
