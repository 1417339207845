import React from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { SliceContainer } from '../Slices/NamedSlice';
import Imgix from 'react-imgix';
import { ReadMoreButton } from 'components/Shared';

const Container = styled(SliceContainer)`
  padding: 20px 30px;
  border: 2px solid #f4f4f4;
  border-radius: 8px;
  margin-top: 40px;

  ${media.md`
    padding: 40px 30px;
  `}
`;

const Image = styled(Imgix)`
  object-fit: cover;
  height: 90px;
`;

const Title = styled.h4`
  margin-top: 20px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const Description = styled.p`
  color: ${({ theme }) => theme.palette.text.primary};
`;

const FourItemsSliceItem = ({
  title,
  description,
  imageUrl,
  link,
  linkTitle,
}) => {
  return (
    <Container>
      <Image
        className="lazyload"
        src={imageUrl}
        sizes="100vw"
        attributeConfig={{
          src: 'data-src',
          srcSet: 'data-srcset',
          sizes: 'data-sizes',
        }}
      />
      <Title>{title}</Title>
      <Description>{description}</Description>
      <ReadMoreButton buttonText={linkTitle} to={link} />
    </Container>
  );
};

export default FourItemsSliceItem;
