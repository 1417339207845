import React from 'react';
import styled from 'styled-components';
import { Row, Col, Container as Grid, media } from 'styled-bootstrap-grid';
import PostListItem from './PostListItem';

const Headline = styled.h2`
  text-align: center;
  margin-bottom: 32px;
`;

const Container = styled.section`
  padding: 32px 0;

  ${media.lg`
    padding: 40px 0 80px;
  `}
`;

const RelatedPosts = ({ posts, pageLang }) => {
  posts = posts.slice(0, 3);
  if (posts.length === 0) return;

  const isGerman = pageLang === 'de-de';

  return (
    <Container>
      <Grid>
        <Row>
          <Col col={12} lg={10} lgOffset={1}>
            <Headline>
              {isGerman ? 'Weitere Beiträge' : 'Related Posts'}
            </Headline>
            <Row>
              {posts.map(({ blog_post: { document } }, index) => {
                if (!document) return null;

                const post = {
                  ...document,
                  lang: pageLang,
                };

                return (
                  <Col col={12} lg={4} key={post?.uid}>
                    <PostListItem post={post} />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Grid>
    </Container>
  );
};

export default RelatedPosts;
