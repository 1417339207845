import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Row, Col, Container as Grid, media } from 'styled-bootstrap-grid';
import Imgix from 'react-imgix';
import { Date } from 'components/Shared';

const TitleContainer = styled.section`
  background-color: ${({ theme }) => theme.palette.success.light};
  text-align: center;
  padding: 32px 16px 0px 16px;
  margin-bottom: 96px;
  ${media.lg`
    padding: 112px 104px 32px 104px;
  `}
`;

const Headline = styled.h1`
  margin-bottom: 48px;
  text-align: left;
`;

const Image = styled(Imgix)`
  margin-bottom: -132px;
  height: 450px;
  width: 100%;
  object-fit: cover;
  box-shadow: ${({ theme }) => theme.shadows.card};
  border-radius: 8px;

  ${media.xs`
    height: 400px;
  `}
`;

const PublishedDate = styled.p`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.grey[500]};
  text-transform: uppercase;
  margin-bottom: 4px;
  text-align: left;
`;

const BlogPostHeader = ({ image, title, publishedAt, pageLang }) => {
  return (
    <Fragment>
      <TitleContainer>
        <Grid>
          <Row>
            <Col col={12} lg={8} lgOffset={2}>
              <PublishedDate>
                <Date isoDate={publishedAt} pageLang={pageLang} />
              </PublishedDate>
              <Headline>{title?.text}</Headline>
              <Image
                className="lazyload"
                htmlAttributes={{
                  alt: image?.alt || 'Blog Post Image',
                }}
                src={image?.url}
                sizes="100vw"
                attributeConfig={{
                  src: 'data-src',
                  srcSet: 'data-srcset',
                  sizes: 'data-sizes',
                }}
              />
            </Col>
          </Row>
        </Grid>
      </TitleContainer>
    </Fragment>
  );
};

export default BlogPostHeader;
