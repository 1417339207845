import styled from 'styled-components';

export const FormControl = styled.div`
  position: relative;
  margin-bottom: 24px;

  ${({ type }) =>
    type === 'checkbox' &&
    `
    display: flex;
    align-items: center;

    input {
      margin-right: 8px;
    }
  `}
`;

export const ErrorMessage = styled.p`
  position: absolute;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.error.main};
  margin-bottom: 0;
  bottom: 0;
  transform: translateY(100%);
`;

export const Input = styled.input`
  padding: 8px 14px;
  background: #fff;
  outline: none !important;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.grey[400]};
  font-size: 14px;
  width: 100%;
  border-radius: 4px;

  &:focus {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }

  ${({ error, theme }) =>
    error &&
    `
    border-color: ${theme.palette.error.main} !important;
  `}
`;

export const Label = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
  font-weight: 600;
`;
