import { createGlobalStyle } from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import 'react-multi-carousel/lib/styles.css';

export const GlobalStyle = createGlobalStyle`
  html {
    overflow-y: initial;
  }

  body {
    color: #0F1412;
    background-color: #ffffff;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
  }

  strong {
    font-weight: 600;
  }

  h1 {
    font-family: Montserrat;
    font-weight: 700;
    margin: 0px;
    font-size: 34px;
    line-height: 42px;
    padding-top: 8px;
    ${media.lg`
      font-size: 38px;
      line-height: 48px;
    `}
  }

  h2 {
    font-weight: 600;
    font-size: 34px;

    ${media.lg`
      font-size: 44px;
    `}
  }

  h3 {
    font-family: Lato;
    font-weight: 200;
    text-transform: uppercase;
    font-size: 16px;
    padding-bottom: 8px;
    letter-spacing: 0.10em;
  }

  h4 {
    font-family: Lato;
    font-weight: 600;
    font-size: 22px;
  }

  h5 {
    font-weight: 600;
    font-size: 22px;
  }

  h6 {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
  }

  .mt-32 {
    margin-top: 32px;
  }

  img {
    margin-bottom: 0;
  }

  .ReactModal__Overlay {
    z-index: 1001;
    background-color: rgba(0, 0, 0, 0.6) !important;
    opacity: 0;
    transition: opacity 1000ms ease-in-out;
  }
  
  .ReactModal__Overlay--after-open{
    opacity: 1;
  }

  .ReactModal__Overlay--before-close{
      opacity: 0;
  }
  
  p {
    font-family: Lato;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
  }

  p a {
    color: #0C193B;

    &:hover {
      text-decoration: underline;
    }
  }

  .hiddenLgUp {
    display: block;

    ${media.lg`
      display: none;
    `}
  }

  .hiddenLgDown {
    display: none;

    ${media.lg`
      display: block;
    `}
  }

  .hiddenMdUp {
    display: block;

    ${media.md`
      display: none;
    `}
  }

  .hiddenMdDown {
    display: none;

    ${media.md`
      display: block;
    `}
  }

  .ReactModal__Overlay {
    transition-duration: 250ms;
  }
`;

export const gridTheme = {
  breakpoints: {
    // defaults below
    xxl: 1600,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 16, // default 15
  },
  col: {
    padding: 16, // default 15
  },
  container: {
    padding: 16, // default 15
    margin: 0,
    maxWidth: {
      // defaults below
      xl: 1320,
    },
  },
};

export const theme = {
  palette: {
    primary: {
      light: '#FFCC47',
      main: '#FFB801',
      dark: '#ECB301',
    },
    secondary: {
      light: '#5D6D98',
      main: '#253561',
      dark: '#0C193B',
    },
    text: {
      primary: '#0C193B',
      secondary: '#FFFFFF',
    },
    success: {
      light: '#f4f5fa',
      main: '#51AD62',
    },
    error: {
      light: '#FEF6F6',
      main: '#CD282B',
    },
    warning: {
      light: '##ffdada',
      main: '#F16133',
    },
    grey: {
      50: '#fafafa',
      100: '#f0f0f0',
      200: '#eeeeee',
      300: '#D7D7D7',
      400: 'lightgrey',
      500: '#9e9e9e',
      600: '#757575',
      700: 'rgba(0,0,0,0.5)',
      800: '#424242',
      900: '#0F1412',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161',
    },
  },
  borders: {
    radius: '6px',
  },
  shadows: {
    card: '4px 4px 24px 10px rgba(0, 0, 0, 0.08)',
    card2: '4px 4px 24px 0px rgba(0, 0, 0, 0.08)',
  },
  sizes: {
    headerOffSet: {
      heightXxl: '40px',
      heightLg: '40px',
      height: '64px',
    },
    fixedHeader: {
      heightXxl: '112px',
      heightLg: '88px',
      height: '64px',
    },
    headerLogo: {
      widthXxl: '144px',
      widthLg: '144px',
      width: '144px',
    },
  },
  ...gridTheme,
};
