import React from 'react';
import { linkResolver } from 'src/utils/linkResolver';
import { RichText } from 'prismic-reactjs';
import { Link } from 'gatsby';

const GatsbyLink = (type, element, content, children, index) => {
  if (element.data.link_type === 'Document') {
    return (
      <Link to={linkResolver(element.data)} key={element.data.id}>
        {content}
      </Link>
    );
  }

  return <a href={element.data.url}>{content}</a>;
};

const EnhancedRichText = ({ render }) => {
  return <RichText render={render} serializeHyperlink={GatsbyLink} />;
};

export default EnhancedRichText;
