import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { media, Container as Grid } from 'styled-bootstrap-grid';

const Container = styled.div`
  height: 100%;

  ${media.sm`
  padding: 0 20px;
  `}

  ${media.md`
  padding: 0 80px;
  `}
`;

const Headline = styled.h3`
  text-align: center;
  margin-bottom: 32px;
`;

const HubspotForm = ({
  data: { region, portal_id: portalId, form_id: formId, version, headline },
}) => {
  // the instructions from the doc did not work. the solution comes from:
  // https://stackoverflow.com/questions/71847130/how-to-add-hubspot-form-to-gatsbyjs-website
  useEffect(() => {
    if ('hbspt' in window) {
      window.hbspt.forms.create({
        region: region.text,
        portalId: portalId.text,
        formId: formId.text,
        version: version.text,
        target: '#hubspotForm',
      });
    }
  }, []);

  return (
    <Container>
      <Grid>
        <Headline>{headline?.text}</Headline>
        <div id="hubspotForm" />
      </Grid>
    </Container>
  );
};

export default HubspotForm;
