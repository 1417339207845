import React from 'react';
import styled from 'styled-components';
import Imgix from 'react-imgix';
import { DateTime } from 'luxon';
import { navigate } from 'gatsby';
import { ReadMoreButton } from 'components/Shared';
import { useRequestDownload } from 'src/hooks';

const ItemContainer = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  height: 100%;
  border-radius: 8px;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.text.primary};
  box-shadow: ${({ theme }) => theme.shadows.card};
  transition: box-shadow 0.2s ease-in-out;
`;

const Image = styled(Imgix)`
  width: 100%;
  height: 250px;
  object-fit: cover;
  text-align: start;
`;

// const PodcastLabel = styled('span')`
//   font-size: 16px;
//   position: absolute;
//   top: 20px;
//   left: 20px;
//   text-transform: uppercase;
//   letter-spacing: 1px;
// `;

// const MicrophoneImage = styled.img`
//   width: 13px;
//   margin-right: 10px;
// `;

const LogoContainer = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 250px;
  background: ${({ theme }) => theme.palette.success.light};
`;

const LogoImage = styled(Imgix)`
  position: absolute;
  width: auto;
  max-height: 60px;
  max-width: 200px;
  left: 25px;
  text-align: start;
`;

const ItemBody = styled.div`
  position: relative;
  min-height: 300px;
  padding: 24px 24px;
`;

const ItemTitle = styled.h4`
  text-align: left;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const ItemSubtitle = styled.p`
  text-align: left;
  color: ${({ theme }) => theme.palette.text.primary};
  padding-bottom: 64px;
`;

const ItemBottom = styled.div`
  position: absolute;
  bottom: 24px;
  left: 24px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
`;

export const Date = styled.p`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.grey[500]};
  text-transform: uppercase;
  margin-bottom: 4px;
`;

const StyledReadMoreButton = styled(ReadMoreButton)`
  width: 110px;
  margin-right: 48px;
  .text {
    font-size: 12px;
  }
`;

const PostListItem = ({ post, setVideo }) => {
  const { handleOpenDownload } = useRequestDownload();
  const { data, first_publication_date, url, lang } = post;
  const {
    featured_image,
    title,
    subtitle,
    company_logo,
    release_date,
    read_more_link,
    press_release_document,
    post_type,
    video_link,
  } = data;

  const isGerman = lang === 'de-de';
  const date = DateTime.fromISO(release_date || first_publication_date);

  return (
    <ItemContainer
      onClick={(e) => {
        if (
          url &&
          !read_more_link?.url &&
          !press_release_document?.url &&
          !video_link?.url
        ) {
          e.preventDefault();
          navigate(url);
        }
      }}
    >
      {/* Remove podcast label for now, maybe position it somewhere else later */}
      {/* {press_type === false && post_type === true && (
        <PodcastLabel>
          <MicrophoneImage src={microphone} alt="Microphone" />
          Podcast
        </PodcastLabel>
      )} */}
      <LogoContainer>
        {company_logo?.url && (
          <LogoImage
            className="lazyload"
            src={company_logo.url}
            htmlAttributes={{
              alt: company_logo.title || 'Company logo',
            }}
            sizes="100vw"
            attributeConfig={{
              src: 'data-src',
              srcSet: 'data-srcset',
              sizes: 'data-sizes',
            }}
          />
        )}
        {featured_image?.url && (
          <Image
            className="lazyload"
            src={featured_image.url}
            sizes="100vw"
            disablePathEncoding={true}
            htmlAttributes={{
              alt: featured_image.title || 'Featured image',
            }}
            attributeConfig={{
              src: 'data-src',
              srcSet: 'data-srcset',
              sizes: 'data-sizes',
            }}
          />
        )}
      </LogoContainer>
      <ItemBody>
        <ItemTitle>
          {title.length === 1 ? title[0]?.text : title.text}
        </ItemTitle>
        <ItemSubtitle>
          {subtitle.length === 1 ? subtitle[0]?.text : subtitle.text}
        </ItemSubtitle>
      </ItemBody>
      <ItemBottom>
        <Date>
          {date
            .setLocale(isGerman ? 'de' : 'en')
            .toLocaleString(DateTime.DATE_FULL)}
        </Date>
        {post_type === false && press_release_document?.url ? ( // press release
          <StyledReadMoreButton
            buttonText={'Download'}
            onClick={() => handleOpenDownload(press_release_document?.url)}
          />
        ) : video_link?.url ? (
          <StyledReadMoreButton
            onClick={() => setVideo(video_link?.url || url)}
            buttonText={isGerman ? 'Video abspielen' : 'Play Video'}
          />
        ) : (
          <StyledReadMoreButton
            buttonText={isGerman ? 'Mehr erfahren' : 'Read More'}
            to={read_more_link?.url || url}
          />
        )}
      </ItemBottom>
    </ItemContainer>
  );
};

export default PostListItem;
