import React from 'react';
import styled from 'styled-components';
import { Row, media, Container as Grid, Col } from 'styled-bootstrap-grid';
import Imgix from 'react-imgix';
import { HighlightedHeadline, ReadMoreButton } from 'components/Shared';
import { SliceContainer, SliceCaption, ContentGrid } from './NamedSlice';
import IntegrationsBackground from 'images/integration.svg';

const StyledCaption = styled(SliceCaption)`
  text-align: center;
  color: ${({ theme }) => theme.palette.secondary.main};
  padding: 16px;

  ${media.lg`
      padding: 32px 16px;
  `}
`;

const IntegrationsRow = styled(Row)`
  box-shadow: 0px 28px 24px -32px ${({ theme }) => theme.palette.grey[400]},
    0px -28px 24px -32px ${({ theme }) => theme.palette.grey[400]};
  padding: 32px;
  margin: 16px;
  ${media.lg`
      margin: 32px 0px;
      padding: 32px 48px;
  `}

  > * {
    box-shadow: 0px 24px 3px -24px ${({ theme }) => theme.palette.grey[400]};

    &:last-child {
      box-shadow: none !important;
    }
  }
`;

const IntegrationRow = styled(Row)`
  padding: 8px 0px;
`;

const IconContainer = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateY(0%) translateX(-50%);
  width: 150px;
  height: 150px;
  background-image: url(${IntegrationsBackground});
  background-size: contain;
`;

const StyledIcon = styled(Imgix)`
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-60%);
  width: 60px;
`;

const ColumnText = styled.p`
  color: ${({ theme }) => theme.palette.text.primary};
  text-align: center;
  margin: 0px;
  padding: 8px 16px;
  ${media.lg`
      padding: 0px;
  `}
`;

const StyledButton = styled(ReadMoreButton)`
  .text {
    width: 150px;
  }

  margin: 16px 0px 16px -32px;
  ${media.lg`
      padding: 0px;
  `}
`;

const Integration = ({
  item: {
    image,
    text,
    button_text: buttonText,
    navigation_link: navigationLink,
  },
}) => {
  return (
    <Grid>
      <IntegrationRow
        alignItems="center"
        justifyContent="center"
        lgJustifyContent="between"
      >
        <Col col={12} lgAuto={true} alignSelf="center">
          <IconContainer>
            <StyledIcon
              className="lazyload"
              src={image.url}
              htmlAttributes={{
                alt: image.alt,
              }}
              sizes="20vw"
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
            />
          </IconContainer>
        </Col>
        <Col auto>
          <ColumnText>{text.text}</ColumnText>
        </Col>
        <Col auto>
          <StyledButton
            className={`plausible-event-name=Integration+Button+Click plausible-event-integration=${text.text}`}
            to={navigationLink.url}
            buttonText={buttonText.text}
          />
        </Col>
      </IntegrationRow>
    </Grid>
  );
};

const IntegrationsSlice = ({
  data: {
    slice_title,
    headline_pre_highlight: pre_highlight,
    headline_highlight: highlight,
    headline_post_highlight: post_highlight,
  },
  items,
}) => {
  return (
    <SliceContainer>
      <ContentGrid>
        {slice_title && (
          <Row alignItems="stretch" justifyContent="center">
            <Col>
              <StyledCaption>{slice_title.text}</StyledCaption>
            </Col>
          </Row>
        )}

        {(pre_highlight.text || highlight.text || post_highlight.text) && (
          <Row alignItems="stretch" justifyContent="center">
            <Col col={12}>
              <HighlightedHeadline
                pre={pre_highlight}
                highlight={highlight}
                post={post_highlight}
                highlightNewline={true}
              />
            </Col>
          </Row>
        )}

        <IntegrationsRow alignItems="center" justifyContent="center">
          {items.map((item, id) => {
            return (
              <Col col={12} key={id}>
                <Integration item={item} key={id} />
              </Col>
            );
          })}
        </IntegrationsRow>
      </ContentGrid>
    </SliceContainer>
  );
};

export default IntegrationsSlice;
