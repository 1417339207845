import React from 'react';
import styled from 'styled-components';
import { Col, Container as Grid, Row, media } from 'styled-bootstrap-grid';
import Imgix from 'react-imgix';
import { SliceContainer, SliceCaption, ContentColumn } from './NamedSlice';

const Image = styled(Imgix)`
  max-width: ${({ theme, small }) => (small ? '70px' : '100px')};
  width: 90%;
  height: auto;

  ${media.lg`
    width: 90%;
    max-width: ${({ theme, small }) => (small ? '70px' : '160px')};
  `}
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 0;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.lg`
    height: ${({ theme, small }) => (small ? '100px' : '160px')};
    margin-bottom: -32px;
  `}
`;

const Link = styled.a`
  display: block;
`;

const StyledCaption = styled(SliceCaption)`
  text-align: center;
`;

const StyledContentColumn = styled(ContentColumn)`
  ${({ small }) => small && 'padding: 0;'}
`;

const StyledSliceContainer = styled(SliceContainer)`
  ${({ small }) => small && 'padding: 32px 0;'}
`;

const BrandItem = ({ link, image, small }) => {
  return (
    <Item>
      <ImageContainer small={small}>
        <Link href={link.url} target="_blank" rel="noreferrer">
          <Image
            className="lazyload"
            src={decodeURI(image.url).replace('+', ' ')}
            htmlAttributes={{ alt: image.alt }}
            sizes="100vw"
            small={small}
            attributeConfig={{
              src: 'data-src',
              srcSet: 'data-srcset',
              sizes: 'data-sizes',
            }}
            imgixParams={{}}
          />
        </Link>
      </ImageContainer>
    </Item>
  );
};

const BrandsSlice = ({ data, items }) => {
  return (
    <StyledSliceContainer small={data.small}>
      <Grid>
        <Row justifyContent="center">
          <StyledContentColumn small={data.small}>
            <StyledCaption>{data.title.text}</StyledCaption>
          </StyledContentColumn>
        </Row>
        <Row justifyContent="center">
          {items.map(
            ({ title = {}, location = {}, image = {}, link = {} }, index) => {
              return (
                <Col col={6} md={2.5} key={link.url + index}>
                  <BrandItem small={data.small} image={image} link={link} />
                </Col>
              );
            }
          )}
        </Row>
      </Grid>
    </StyledSliceContainer>
  );
};

export default BrandsSlice;
