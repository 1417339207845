import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Row, Container as Grid, Col, media } from 'styled-bootstrap-grid';
import { SecondaryButton } from 'components/Shared';
import HighlightedHeadline from 'components/Shared/HighlightedHeadline';
import { ContentGrid, SliceContainer } from './NamedSlice';

const SliceCaption = styled.h3`
  text-align: left;
  margin: 0px;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const SliceText = styled.p`
  padding: 12px 0px;
  text-align: left;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const TextBlock = styled(Col)`
  padding: 24px 0px;
  margin: 0px;

  ${media.md`
    padding-right: 24px;
  `}

  ${media.lg`
    padding: 0px 56px;
  `}
`;

const StyledHeadline = styled(HighlightedHeadline)`
  text-align: left;
`;

const TextBlockTitle = styled.h4`
  margin: 0px;
  padding: 0px 0px 8px 0px;
  text-align: left;

  ${media.lg`
      padding: 0px 0px 8px 0px;
  `}
`;

const NamedSliceDivided = ({
  data: {
    slice_title,
    headline_pre_highlight: pre_highlight,
    headline_highlight: highlight,
    headline_post_highlight: post_highlight,
    text,
    button_text,
    navigation_link,
    highlight_newline,
  },
  items,
}) => {
  return (
    <SliceContainer>
      <ContentGrid>
        <Row alignItems="stretch" justifyContent="space-between">
          <Col col={12} lg={6}>
            <SliceCaption>{slice_title.text}</SliceCaption>
            <StyledHeadline
              pre={pre_highlight}
              highlight={highlight}
              post={post_highlight}
              highlight_newline={highlight_newline}
            />
            <div>
              {text?.text && <SliceText>{text.text}</SliceText>}
              {button_text.text && (
                <SecondaryButton to={navigation_link?.url} size="small">
                  {button_text.text}
                </SecondaryButton>
              )}
            </div>
          </Col>
          <Col col={12} lg={6}>
            <Grid>
              <Row>
                {items.map((item, index) => {
                  return (
                    <TextBlock col={12} md={6} lg={12} key={index}>
                      <TextBlockTitle>{item.item_headline.text}</TextBlockTitle>
                      <SliceText>{item.item_text.text}</SliceText>
                    </TextBlock>
                  );
                })}
              </Row>
            </Grid>
          </Col>
        </Row>
      </ContentGrid>
    </SliceContainer>
  );
};

export default NamedSliceDivided;
