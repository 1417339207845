import React from 'react';
import styled from 'styled-components';
import { Row, Container as Grid, Col, media } from 'styled-bootstrap-grid';
import { HighlightedHeadline } from 'components/Shared';
import { SliceContainer } from './NamedSlice';
import Imgix from 'react-imgix';

const DarkContainer = styled(SliceContainer)`
  background-color: ${({ theme }) => theme.palette.secondary.dark};
  padding: 40px 0px;

  ${media.md`
    padding: 64px 0px;
  `}
`;

const SliceCaption = styled.h3`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const SliceText = styled.p`
  padding: 24px 0px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const ContentColumn = styled(Col)`
  height: 100%;
  text-align: center;
`;

const BenefitGrid = styled(Grid)`
  padding: 0px;
  margin: 0px;
`;

const BenefitContainer = styled.div`
  position: relative;
  border-radius: 8px;
  padding: 16px 48px;

  ${media.lg`
    padding: 16px 96px;
  `}
`;

const BenefitImage = styled(Imgix)`
  object-fit: cover;
  width: 100px;
`;

const BenefitTitle = styled.h4`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const BenefitDescription = styled.p`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const Benefit = ({ item: { title1: title, text, image } }) => {
  return (
    <BenefitContainer>
      <BenefitImage
        className="lazyload"
        src={image?.url}
        htmlAttributes={{ alt: image?.alt }}
        sizes="100vw"
        attributeConfig={{
          src: 'data-src',
          srcSet: 'data-srcset',
          sizes: 'data-sizes',
        }}
      />
      <BenefitTitle>{title.text}</BenefitTitle>
      <BenefitDescription>{text.text}</BenefitDescription>
    </BenefitContainer>
  );
};

const BenefitsSlice = ({
  data: {
    section_title: sliceTitle,
    headline_pre_highlight: preHighlight,
    headline_highlight: highlight,
    headline_post_highlight: postHighlight,
    text,
  },
  items,
}) => {
  return (
    <DarkContainer>
      <Grid>
        <Row alignItems="center" justifyContent="center">
          {sliceTitle.text && (
            <ContentColumn col={12}>
              <SliceCaption>{sliceTitle.text}</SliceCaption>
            </ContentColumn>
          )}

          {(preHighlight || highlight || postHighlight) && (
            <ContentColumn col={12}>
              <HighlightedHeadline
                pre={preHighlight}
                highlight={highlight}
                post={postHighlight}
                highlightNewline={true}
                hasDarkBackground={true}
              />
            </ContentColumn>
          )}

          {text.text && (
            <ContentColumn col={12}>
              <SliceText>{text.text}</SliceText>
            </ContentColumn>
          )}

          <ContentColumn col={12}>
            <BenefitGrid>
              <Row alignItems="stretch" justifyContent="between">
                {items.map((item, index) => {
                  return (
                    <Col col={12} md={6} key={index}>
                      <Benefit item={item} />
                    </Col>
                  );
                })}
              </Row>
            </BenefitGrid>
          </ContentColumn>
        </Row>
      </Grid>
    </DarkContainer>
  );
};

export default BenefitsSlice;
