import React from 'react';
import styled from 'styled-components';
import { Row, media, Container as Grid, Col } from 'styled-bootstrap-grid';
import { PrimaryButton } from 'components/Shared';
import { useRequestDownload } from 'src/hooks';

const Headline = styled.h2`
  font-size: 30px;
`;

const SliceContainer = styled.div`
  position: relative;
  z-index: 10;
  overflow: hidden;
`;

const StyledGrid = styled(Grid)`
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  padding: 40px 16px;

  ${media.md`
      padding: 64px 0px;
  `}

  ${media.xxl`
      padding: 104px 0px;
  `}
`;

const ContentColumn = styled(Col)`
  height: 100%;
  margin-bottom: 16px;

  ${({ center }) =>
    center &&
    `
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    margin: 0px 32px;
    padding: 16px 0px;
  `}
`;

const SliceText = styled.div`
  margin: 32px 0;

  p {
    padding: 0px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
`;

const DarkDividerSlice = ({
  data: { button_text, headline, navigation_link, text },
}) => {
  const { handleOpenDownload } = useRequestDownload();

  return (
    <SliceContainer>
      <StyledGrid>
        <Row justifyContent={'center'}>
          <ContentColumn md={9} mdOffset={1} center={true}>
            <Headline>{headline.text}</Headline>
            {text?.html && (
              <SliceText dangerouslySetInnerHTML={{ __html: text.html }} />
            )}
            <ButtonContainer>
              {button_text.text && (
                <PrimaryButton
                  onClick={() => handleOpenDownload(navigation_link?.url)}
                  size="small"
                >
                  {button_text.text}
                </PrimaryButton>
              )}
            </ButtonContainer>
          </ContentColumn>
        </Row>
      </StyledGrid>
    </SliceContainer>
  );
};

export default DarkDividerSlice;
