import React from 'react';
import styled from 'styled-components';
import { HighlightedHeadline, FourItemsSliceItem } from 'components/Shared';
import { Row, Container as Grid, Col, media } from 'styled-bootstrap-grid';

const Container = styled.div`
  height: 100%;
  margin-bottom: 80px;

  ${media.sm`
  margin: 20px 0 80px;
  `}

  ${media.xl`
  margin: 60px 60px 120px;
  `}
`;

const ContentGrid = styled(Grid)`
  position: relative;
  z-index: 10;
  padding: 0;

  ${media.xl`
      padding: 0px 88px;
  `}
`;

const ContentColumn = styled(Col)`
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  text-align: start;
`;

const HeadlineContainer = styled.div`
  padding: 2rem 1rem 0;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;

  ${media.md`
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  `}
`;

const ItemContainer = styled.div`
  padding: 1rem 1rem 0;

  ${media.md`
  adding: 2rem 1rem 0;
  `}
`;

const FourItemsSlice = ({
  data: {
    headline_pre_highlight: preHighlight,
    headline_highlight: highlight,
    headline_post_highlight: postHighlight,
  },
  items,
}) => {
  return (
    <Container>
      <ContentGrid>
        <Row alignItems="stretch" justifyContent="start">
          <ContentColumn col={12} alignItems="start" justifyContent="start">
            <HeadlineContainer>
              <HighlightedHeadline
                pre={preHighlight}
                highlight={highlight}
                post={postHighlight}
                highlightNewline={false}
                hasDarkBackground={false}
              />
            </HeadlineContainer>
            <ItemContainer>
              <Row alignItems="stretch" justifyContent="between">
                {items.map(
                  ({ title, description, image, link, link_title }, index) => {
                    return (
                      <Col col={12} md={6} key={index}>
                        <FourItemsSliceItem
                          title={title.text}
                          description={description.text}
                          imageUrl={image.url}
                          link={link.url}
                          linkTitle={link_title.text}
                        />
                      </Col>
                    );
                  }
                )}
              </Row>
            </ItemContainer>
          </ContentColumn>
        </Row>
      </ContentGrid>
    </Container>
  );
};

export default FourItemsSlice;
