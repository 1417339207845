import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { X } from '@styled-icons/bootstrap/X';
import ReactPlayer from 'react-player';
import { useBreakpoint, useWindowSize } from '../../hooks';

const Container = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
`;

const HEIGHT = 534;
const HEIGHT_MOBILE = 242;

const Close = styled(X)`
  color: #fff;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  z-index: 1;
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem;

  iframe {
    border-radius: 4px;
  }
`;

const VideoDialog = ({ open, onClose, url }) => {
  const isMobile = useBreakpoint('sm');
  const { height: windowHeight } = useWindowSize();

  const customStyles = {
    content: {
      zIndex: 1001,
      width: '900px',
      margin: '0 auto',
      padding: '0',
      background: 'transparent',
      border: 'none',
      top: '50%',
      left: 16,
      right: 16,
      transform: 'translateY(-50%)',
      maxWidth: isMobile ? 'calc(100% - 10px)' : 'calc(100% - 32px)',
      // for landscape mode: check if window height is greater than 534
      height: isMobile
        ? HEIGHT_MOBILE
        : windowHeight < HEIGHT
        ? windowHeight
        : HEIGHT,
    },
  };

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      ariaHideApp={false}
      style={customStyles}
      contentLabel="Video Modal"
    >
      <Container onClick={onClose}>
        <Close size={22} onClick={onClose} />
        <VideoContainer>
          <ReactPlayer
            url={url}
            width="100%"
            height="100%"
            controls={true}
            playing={true}
            loop={true}
            config={{
              vimeo: {
                playerOptions: { dnt: 1 },
              },
            }}
          />
        </VideoContainer>
      </Container>
    </Modal>
  );
};

export default VideoDialog;
