import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import Imgix from 'react-imgix';
import VisibilitySensor from 'react-visibility-sensor';
import { useSpring, animated, config } from 'react-spring';

const StyledImage = styled(Imgix)`
  object-fit: contain;
  margin: 0px 8px 0px 8px;
  width: 350px;
  height: 100%;
  border-radius: 8px;
  ${media.md`
    width: 90%;
    height: 100%;
  `}

  ${media.lg`
    width: 100%;
    height: 100%;
  `}
`;

const FadeInDirection = ({ invertDirection, isVisible, children }) => {
  const props = useSpring({
    opacity: isVisible ? 1 : 0.01,
    transform: isVisible
      ? 'translateX(0px)'
      : invertDirection
      ? 'translateX(-80px)'
      : 'translateX(80px)',
    delay: 500,
    config: { ...config.stiff },
  });
  return <animated.div style={props}>{children}</animated.div>;
};

const RevealedImage = ({ images, invertOrder }) => {
  const [isVisible, setVisibility] = useState(false);
  const onChange = (visiblity) => {
    visiblity && setVisibility(visiblity);
  };

  const desktopImage = images[0].image;
  const mobileImage = images[1]?.image || desktopImage;

  return (
    <div>
      <div className="hiddenLgDown">
        <VisibilitySensor partialVisibility onChange={onChange}>
          <FadeInDirection invertDirection={invertOrder} isVisible={isVisible}>
            <StyledImage
              className="lazyload"
              src={desktopImage.url}
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
              props
              htmlAttributes={{
                alt: desktopImage.alt,
              }}
            />
          </FadeInDirection>
        </VisibilitySensor>
      </div>
      <div className="hiddenLgUp">
        <VisibilitySensor partialVisibility onChange={onChange}>
          <FadeInDirection invertDirection={invertOrder} isVisible={isVisible}>
            <StyledImage
              className="lazyload"
              src={mobileImage.url}
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
              htmlAttributes={{
                alt: mobileImage.alt,
              }}
            />
          </FadeInDirection>
        </VisibilitySensor>
      </div>
    </div>
  );
};

export default RevealedImage;
