import React from 'react';
import styled from 'styled-components';
import { Row, media, Container as Grid, Col } from 'styled-bootstrap-grid';
import Imgix from 'react-imgix';
import { useBreakpoint } from 'hooks';
import { HighlightedHeadline, ReadMoreButton } from 'components/Shared';
import { SliceContainer, SliceCaption, ContentGrid } from './NamedSlice';

const ContentColumn = styled(Col)`
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  text-align: start;
  padding: 8px 16px;

  ${media.lg`
      padding: 8px 16px;
  `}
`;

const StyledCaption = styled(SliceCaption)`
  text-align: center;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const PaddedRow = styled(Row)`
  padding: 16px 0px;

  ${media.lg`
     padding: 16px 0px;
  `}
`;

const PaddedCol = styled(Col)`
  padding: 24px 0px;

  ${media.lg`
     padding: 16px 0px;
  `}
`;

const IconContainer = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  box-shadow: ${({ theme }) => theme.shadows.card};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0%;
  background-color: #fff;

  ${media.lg`
     left: 15%;
  `}
`;

const StyledIcon = styled(Imgix)`
  display: block;
  height: 60px;
`;

const ColumnHeadline = styled.p`
  color: ${({ theme }) => theme.palette.text.primary};
  text-align: left;
  font-family: Montserrat;
  font-weight: 700;
  font-size: 22px;
  margin: 0px;
`;

const ColumnText = styled.p`
  color: ${({ theme }) => theme.palette.text.primary};
  text-align: left;
  margin: 0px;
`;

const StyledReadMoreButton = styled(ReadMoreButton)`
  width: 110px;
  left: -6px;

  .text {
    font-size: 12px;
  }
`;

const StyledReadMoreButtonRow = styled(ReadMoreButton)`
  align-items: center;
  width: 110px;
  left: -6px;

  .text {
    font-size: 12px;
  }
`;

const Box = styled('div')`
  position: relative;
`;

const RowContent = ({
  item: { icon, text, title1: title, navigation_link, lang },
  pageLang,
}) => {
  const isGerman = pageLang === 'de-de';
  const alignCenter = useBreakpoint('lg');
  return (
    <Grid>
      <PaddedRow
        alignItems="center"
        justifyContent={alignCenter ? 'center' : 'start'}
      >
        <PaddedCol auto md={4} col={3}>
          <IconContainer>
            {icon.url && (
              <StyledIcon
                className="lazyload"
                src={icon.url}
                sizes="100vw"
                htmlAttributes={{
                  alt: icon.alt,
                }}
                attributeConfig={{
                  src: 'data-src',
                  srcSet: 'data-srcset',
                  sizes: 'data-sizes',
                }}
              />
            )}
          </IconContainer>
        </PaddedCol>
        <PaddedCol lg={8} col={12}>
          <ColumnHeadline>{title.text}</ColumnHeadline>
          <p></p>
          <ColumnText>{text.text}</ColumnText>
          {navigation_link.url && (
            <Box>
              <StyledReadMoreButtonRow
                buttonText={isGerman ? 'Mehr erfahren' : 'Read More'}
                to={navigation_link.url}
              />
            </Box>
          )}
        </PaddedCol>
      </PaddedRow>
    </Grid>
  );
};

const ColumnContent = ({
  item: { button_text, navigation_link, icon, text, title1: title },
}) => {
  return (
    <Grid>
      <PaddedRow alignItems="center" justifyContent="start">
        {icon.url && (
          <StyledIcon
            className="lazyload"
            src={icon?.url}
            htmlAttributes={{
              alt: icon.alt,
            }}
            sizes="100vw"
            attributeConfig={{
              src: 'data-src',
              srcSet: 'data-srcset',
              sizes: 'data-sizes',
            }}
          />
        )}
      </PaddedRow>
      <PaddedRow alignItems="start" justifyContent="start">
        <ColumnHeadline>{title.text}</ColumnHeadline>
      </PaddedRow>

      <PaddedRow alignItems="start" justifyContent="start">
        <ColumnText>{text.text}</ColumnText>
      </PaddedRow>

      {button_text.text && (
        <PaddedRow alignItems="start" justifyContent="start">
          <StyledReadMoreButton
            buttonText={button_text.text}
            to={navigation_link.url}
          />
        </PaddedRow>
      )}
    </Grid>
  );
};

const ThreeColumnsSlice = ({
  data: {
    slice_title,
    headline_pre_highlight: pre_highlight,
    headline_highlight: highlight,
    headline_post_highlight: post_highlight,
    show_rows,
    has_iced_background: hasIcedBackground,
  },
  items,
  pageLang,
}) => {
  return (
    <SliceContainer hasDarkBackground={hasIcedBackground}>
      <ContentGrid>
        {slice_title && (
          <Row alignItems="stretch" justifyContent="center">
            <ContentColumn col={12} alignItems="center" justifyContent="center">
              <StyledCaption>{slice_title.text}</StyledCaption>
            </ContentColumn>
          </Row>
        )}

        {(pre_highlight.text || highlight.text || post_highlight.text) && (
          <Row alignItems="stretch" justifyContent="center">
            <ContentColumn col={12} alignItems="center" justifyContent="center">
              <HighlightedHeadline
                pre={pre_highlight}
                highlight={highlight}
                post={post_highlight}
                highlightNewline={true}
              />
            </ContentColumn>
          </Row>
        )}
        <Row alignItems="stretch" justifyContent="between">
          {items.map((item, id) => {
            return (
              <ContentColumn
                col={12}
                lg={show_rows ? 12 : 3.5}
                alignItems="start"
                justifyContent="start"
                key={id}
              >
                {show_rows ? (
                  <RowContent item={item} key={id} pageLang={pageLang} />
                ) : (
                  <ColumnContent item={item} key={id} />
                )}
              </ContentColumn>
            );
          })}
        </Row>
      </ContentGrid>
    </SliceContainer>
  );
};

export default ThreeColumnsSlice;
