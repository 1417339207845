import React from 'react';
import styled from 'styled-components';
import Imgix from 'react-imgix';
import { Row, Col } from 'styled-bootstrap-grid';

const Image = styled(Imgix)`
  width: 100%;
  height: 400px;
  object-fit: cover;
`;

const Subtitle = styled.p`
  font-size: 12px !important;
  line-height: 1.2;
  color: ${({ theme }) => theme.palette.grey[500]};
`;

const Container = styled(Row)`
  flex-direction: ${({ ltr }) => (ltr ? 'row' : 'row-reverse')};
`;

const TextImageSlice = ({
  data: { image, image_subtitle, text, left_to_right },
}) => {
  return (
    <Container ltr={left_to_right}>
      <Col col={12} lg={6}>
        <Image
          className="lazyload"
          htmlAttributes={{
            alt: image?.alt,
          }}
          src={image?.url}
          sizes="100vw"
          attributeConfig={{
            src: 'data-src',
            srcSet: 'data-srcset',
            sizes: 'data-sizes',
          }}
        />
        <Subtitle>{image_subtitle.text}</Subtitle>
      </Col>
      <Col col={12} lg={6}>
        <div dangerouslySetInnerHTML={{ __html: text?.html }} />
      </Col>
    </Container>
  );
};

export default TextImageSlice;
